import { yupResolver } from "@hookform/resolvers/yup";
import { WorkAreaType } from "../../../../../shared/types/work-area.type";
import yup from "../../../../../shared/yupLocale";

const WorkAreaSchema: yup.SchemaOf<Omit<WorkAreaType, "id">> = yup
  .object()
  .shape({
    name: yup.string().required("Esta campo es requerido"),
  });

export const ModalWorkAreaResolver = yupResolver(WorkAreaSchema);
