import { AxiosResponse } from "axios";
import { JobType } from "../types/job.type";
import { PostulationType } from "../types/postulation.type";
import axiosClient from "../utils/api/clientAxios";

const API = axiosClient.getUri();
type GetPostulationsApi = Array<PostulationType & { job: JobType }>;
type CreatePostulationApi = PostulationType & {
  job: JobType;
};
type EditPostulationApi = PostulationType & {
  job: JobType;
};
type ChangePostulationApi = PostulationType & {
  job: JobType;
};
type DeletePostulationApi = { id: number };

export type GetPostulationsResponse = AxiosResponse<
  GetPostulationsApi,
  GetPostulationsApi
>;
export type CreatePostulationResponse = AxiosResponse<
  CreatePostulationApi,
  CreatePostulationApi
>;
export type EditPostulationResponse = AxiosResponse<
  EditPostulationApi,
  EditPostulationApi
>;
export type ChangePostulationResponse = AxiosResponse<
  ChangePostulationApi,
  ChangePostulationApi
>;
export type DeletePostulationResponse = AxiosResponse<
  DeletePostulationApi,
  DeletePostulationApi
>;

const url = `${API}/postulation`;

/**
 * In this method we get all Postulations from the API
 * @returns {Array<PostulationType>}
 */
export const getAllPostulations =
  async (): Promise<GetPostulationsResponse> => {
    return await axiosClient.get(`${url}`);
  };

/**
 * In this method we create a new Postulation from the API
 * @param {PostulationType} postulation
 * @returns
 */
export const createPostulation = async (
  postulation: Omit<PostulationType, "id" | "view" | "approved">
): Promise<CreatePostulationResponse> => {
  const formData = new FormData();
  formData.append("name", postulation.name);
  formData.append("lastname", postulation.lastname);
  formData.append("email", postulation.email);
  formData.append("address", postulation.address);
  formData.append("age", postulation.age + "");
  formData.append("gender", postulation.gender);
  formData.append("jobId", postulation.jobId + "");
  formData.append("phone", postulation.phone);
  if (postulation.cv) formData.append("cv", postulation.cv);
  return await axiosClient.post(`${url}`, formData, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * In this method we edit a Postulation by Id from the API
 * @param {number} id Postulation's id
 * @param {PostulationType} postulation
 * @returns
 */
export const editPostulation = async (
  id: number,
  postulation: Omit<PostulationType, "id" | "view" | "state">
): Promise<EditPostulationResponse> => {
  return await axiosClient.put(`${url}/${id}`, postulation);
};

/**
 * In this methods we delete a Postulation by Id from the API
 * @param {number} id Postulation's id
 * @returns
 */
export const changePostulation = async (
  id: number,
  postulation: { view?: boolean; state?: number; description?: string }
): Promise<ChangePostulationResponse> => {
  return await axiosClient.patch(`${url}/${id}`, postulation);
};

/**
 * In this methods we delete a Postulation by Id from the API
 * @param {number} id Postulation's id
 * @returns
 */
export const deletePostulation = async (
  id: number
): Promise<DeletePostulationResponse> => {
  return await axiosClient.delete(`${url}/${id}`);
};
