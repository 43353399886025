import Container from "../../ui/Container";
import { Splide, SplideSlide } from "@splidejs/react-splide";
// Default theme
import "@splidejs/react-splide/css";

// or other themes
import "@splidejs/react-splide/css/skyblue";
import "@splidejs/react-splide/css/sea-green";

// or only core styles
import "@splidejs/react-splide/css/core";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import paths from "../../shared/routes/paths";

import image1 from "../../shared/assets/img/image1.jpg";
import image2 from "../../shared/assets/img/image2.jpg";
import image3 from "../../shared/assets/img/image3.jpg";
import image4 from "../../shared/assets/img/image4.jpg";

const Home = () => {
  return (
    <Container width="100%" position="relative" height="calc(100vh - 9.5rem)">
      <ContainerTitle
        width="100%"
        height="80px"
        display="flex"
        justifyContent="center"
        gap="10px"
        padding="10px"
      >
        <AnchorStyled
          target="_blank"
          href={"http://www.capacitacion.plantaacuapesca.com/user/login"}
        >
          CAPACITATE
        </AnchorStyled>
        <LinkStyled to={paths.postulacion}>Trabaja con nosotros</LinkStyled>
      </ContainerTitle>
      <ContainerSplide
        options={{
          arrows: true,
          autoplay: true,
          speed: 300,
        }}
      >
        <SplideSlide>
          <StyledImg src={image1} alt="Image 1" />
        </SplideSlide>
        <SplideSlide>
          <StyledImg src={image2} alt="Image 2" />
        </SplideSlide>
        <SplideSlide>
          <StyledImg src={image3} alt="Image 3" />
        </SplideSlide>
        <SplideSlide>
          <StyledImg src={image4} alt="Image 4" />
        </SplideSlide>
      </ContainerSplide>
    </Container>
  );
};

export default Home;

const StyledImg = styled.img`
  width: 100%;
  object-fit: cover;
  height: calc(100vh - 9.5rem);
`;

const ContainerSplide = styled(Splide)`
  padding: 0;
  width: 100%;
  height: calc(100vh - 9.5rem);

  ${({ theme }) => css`
    .splide__arrow {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      border: 1px solid ${theme.colors.Secondary5};
      background-color: ${theme.colors.Neutral0};
      opacity: 1;

      svg {
        fill: ${theme.colors.Secondary5} !important;
      }

      svg:hover {
        fill: ${theme.colors.Secondary5} !important;
      }
    }

    .splite__arrow:hover {
      opacity: 1;
    }
  `}
`;

const ContainerTitle = styled(Container)`
  top: 50%;
  right: 10%;
`;

const LinkStyled = styled(Link)`
  ${({ theme }) => css`
    text-transform: uppercase;
    border: 2px solid ${theme.colors.Primary6};
    font-size: 15px;
    padding: 10px;
    transition: all 500ms;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${theme.colors.Primary5};
    box-shadow: 2px 2px 10px #fff;
    border-radius: 10px;
    color: ${theme.colors.Neutral2};
    font-weight: bold;
    :hover {
      color: #000;
      background-color: ${theme.colors.Neutral2};
      border: 2px solid ${theme.colors.Primary4};
      box-shadow: 2px 2px 10px ${theme.colors.Neutral8};
    }
  `};
`;
const AnchorStyled = styled.a`
  ${({ theme }) => css`
    text-transform: uppercase;
    border: 2px solid ${theme.colors.Primary6};
    font-size: 15px;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 500ms;
    text-align: center;
    background-color: ${theme.colors.Primary5};
    box-shadow: 2px 2px 10px #fff;
    border-radius: 10px;
    color: ${theme.colors.Neutral2};
    font-weight: bold;
    :hover {
      color: #000;
      background-color: ${theme.colors.Neutral2};
      border: 2px solid ${theme.colors.Primary4};
      box-shadow: 2px 2px 10px ${theme.colors.Neutral8};
    }
  `};
`;
