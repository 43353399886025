import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { useAadminContext } from "../../../../../shared/contexts/AdminProvider";
import {
  createUser,
  CreateUserResponse,
  editUser,
  EditUserResponse,
} from "../../../../../shared/services/user.service";
import Button from "../../../../../ui/Button";
import Container from "../../../../../ui/Container";
import TextAreaField from "../../../../../ui/fields/TextAreaField";
import notification from "../../../../../ui/notification";
import Select from "../../../../../ui/Select";
import { SelectItemType } from "../../../../../ui/Select/interfaces";
import { UserFormType } from "../../hookforms.interfaces";

const UserModal = () => {
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
  } = useFormContext<UserFormType>();

  const {
    role: { roles },
  } = useAadminContext();

  const { isLoading: loadingCreateUser, mutate: createUserMutate } =
    useMutation<CreateUserResponse, Error>(
      async (): Promise<CreateUserResponse> => {
        const { id, state, users, showModalUser, ...restUser } = getValues();
        return await createUser(restUser);
      },
      {
        onSuccess: ({ data }) => {
          setValue("users", [data, ...watch("users")]);
          setValue("showModalUser", false);
          onCleanFields();
          notification({ type: "success", message: "Usuario creado" });
        },
        onError: (error: any) => {
          notification({
            type: "error",
            message: error.response.data.message,
          });
        },
      }
    );

  const { isLoading: loadingEditUser, mutate: editUserMutate } = useMutation<
    EditUserResponse,
    Error
  >(
    async (): Promise<EditUserResponse> => {
      const { id, state, users, showModalUser, ...restUser } = getValues();
      return await editUser(id, restUser);
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "users",
          watch("users").map((user) => {
            if (user.id === data.id) return data;
            return user;
          })
        );
        setValue("showModalUser", false);
        onCleanFields();
        notification({ type: "success", message: "Usuario editado" });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const onCleanFields = () => {
    setValue("id", 0);
    setValue("name", "");
    setValue("password", "");
    setValue("email", "");
    setValue("roleId", 0);
  };

  const optionsStates: Array<SelectItemType> = roles.map((role) => {
    return {
      key: String(role.id),
      label: role.name,
    };
  });

  const onAddUser = () => {
    handleSubmit((e) => {
      createUserMutate();
    })();
  };

  const onEditUser = () => {
    handleSubmit(() => {
      editUserMutate();
    })();
  };

  return (
    <Container width="100%">
      <Container
        width="100%"
        height="100%"
        padding="20px"
        display="flex"
        flexDirection="column"
        gap="20px"
        justifyContent="space-between"
        alignItems={"center"}
      >
        {/* Info */}
        <Container
          width="100%"
          display="flex"
          flexDirection="column"
          gap="40px"
        >
          {/* name field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextAreaField
                  label="Nombre Completo"
                  width="100%"
                  rows={1}
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Container>

          {/* email field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextAreaField
                  width="100%"
                  label="Correo Electrónico"
                  rows={1}
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors.email}
                  helperText={errors.email?.message}
                />
              )}
            />
          </Container>

          {/* password field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <TextAreaField
                  width="100%"
                  label="Contraseña"
                  rows={1}
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors.password}
                  helperText={errors.password?.message}
                />
              )}
            />
          </Container>

          {/* role field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="roleId"
              control={control}
              render={({ field }) => (
                <Select
                  label="Rol"
                  width="100%"
                  value={String(field.value)}
                  options={optionsStates}
                  onChange={(key) => {
                    field.onChange(parseInt(key));
                  }}
                  hasError={!!errors.roleId}
                  helperText={errors.roleId?.message}
                />
              )}
            />
          </Container>
        </Container>

        {/* Button Submit */}
        <Container width="100%">
          <Button
            display="default"
            shape={"default"}
            width="100%"
            label={watch("id") === 0 ? "Agregar Usuario" : "Editar Usuario"}
            trailingIcon={watch("id") === 0 ? "ri-add-fill" : "ri-edit-fill"}
            loading={loadingEditUser || loadingCreateUser}
            disabled={loadingEditUser || loadingCreateUser}
            onClick={watch("id") === 0 ? onAddUser : onEditUser}
          ></Button>
        </Container>
      </Container>
    </Container>
  );
};

export default UserModal;
