import React from "react";
import { useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import { useAadminContext } from "../../../../../../shared/contexts/AdminProvider";
import { WorkAreaType } from "../../../../../../shared/types/work-area.type";
import Container from "../../../../../../ui/Container";
import WorkAreaModalRow from "./WorkAreaModalRow";

const WorkAreaModalTable = () => {
  const {
    workArea: { workAreas },
  } = useAadminContext();
  const { watch } = useFormContext<WorkAreaType>();

  return (
    <StyledContainer
      width="100%"
      minHeight="100px"
      overFlowY="auto"
    >
      <div>
        {workAreas.map((workArea: WorkAreaType, index: number) => {
          return (
            <WorkAreaModalRow
              key={index}
              id={index + 1}
              workAreaId={workArea.id}
              selected={workArea.id === watch("id")}
              name={workArea.name}
            />
          );
        })}
      </div>
    </StyledContainer>
  );
};

export default WorkAreaModalTable;

const StyledContainer = styled(Container)`
  ${({ theme }) => css`
    border-radius: 8px;
    border: 2px solid ${theme.colors.Neutral4};
  `}
`;
