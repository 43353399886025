import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { useAadminContext } from "../../../../../../shared/contexts/AdminProvider";
import {
  createCentralOffice,
  deleteCentralOffice,
  editCentralOffice,
} from "../../../../../../shared/services/central-office.service";
import { CentralOfficeType } from "../../../../../../shared/types/central-office.type";
import Button from "../../../../../../ui/Button";
import Container from "../../../../../../ui/Container";
import notification from "../../../../../../ui/notification";

const CentralOfficeModalActions = () => {
  const { setValue, getValues, handleSubmit, watch } =
    useFormContext<CentralOfficeType>();

  const {
    centralOffice: { setCentralOffices, centralOffices },
  } = useAadminContext();

  const {
    isLoading: loadingCreateCentralOffice,
    mutate: createCentralOfficeMutate,
  } = useMutation<any, Error>(
    async () => {
      const { id, ...restCentralOffice } = getValues();
      return await createCentralOffice(restCentralOffice);
    },
    {
      onSuccess: ({ data }) => {
        setCentralOffices([...centralOffices, data]);
        onCleanFields();
        notification({ type: "success", message: "Sede creada" });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const {
    isLoading: loadingEditCentralOffice,
    mutate: editCentralOfficeMutate,
  } = useMutation<any, Error>(
    async () => {
      const { id, ...restCentralOffice } = getValues();
      return await editCentralOffice(id, restCentralOffice);
    },
    {
      onSuccess: ({ data }) => {
        setCentralOffices(
          centralOffices.map((workArea) => {
            if (workArea.id === Number(data.id)) return data;
            return workArea;
          })
        );
        onCleanFields();
        notification({ type: "success", message: "Sede editada" });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const {
    isLoading: loadingDeleteCentralOffice,
    mutate: deleteCentralOfficeMutate,
  } = useMutation<any, Error>(
    async () => {
      const { id } = getValues();
      return await deleteCentralOffice(id);
    },
    {
      onSuccess: ({ data }) => {
        setCentralOffices(
          centralOffices.filter((workArea) => workArea.id !== data.id)
        );
        onCleanFields();
        notification({
          type: "success",
          message: "Sede eliminada",
        });
      },
      onError: (error: any) => {
        if (error.response.data.message.search("foreign key") !== -1) {
          notification({
            type: "error",
            message:
              "Hay Puestos de Trabajo asociados a esta Sede, no se puede eliminar",
          });
          return;
        }
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const onCleanFields = () => {
    setValue("id", 0);
    setValue("name", "");
  };

  const onAddCentralOffice = () => {
    handleSubmit(() => {
      createCentralOfficeMutate();
    })();
  };
  const onEditCentralOffice = () => {
    handleSubmit(() => {
      editCentralOfficeMutate();
    })();
  };
  const onDeleteCentralOffice = () => {
    handleSubmit(() => {
      deleteCentralOfficeMutate();
    })();
  };
  return (
    <Container
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="20px"
    >
      <Button
        width="100px"
        shape="round"
        trailingIcon="ri-add-fill"
        disabled={watch("id") !== 0}
        onClick={onAddCentralOffice}
        loading={loadingCreateCentralOffice}
      />
      <Button
        width="100px"
        shape="round"
        trailingIcon="ri-edit-2-line"
        disabled={watch("id") === 0}
        onClick={onEditCentralOffice}
        loading={loadingEditCentralOffice}
      />
      <Button
        width="100px"
        shape="round"
        display="danger"
        trailingIcon="ri-close-line"
        disabled={watch("id") === 0}
        onClick={onDeleteCentralOffice}
        loading={loadingDeleteCentralOffice}
      />
      <Button
        width="100px"
        shape="round"
        display="warning"
        trailingIcon="ri-brush-2-line"
        onClick={onCleanFields}
      />
    </Container>
  );
};

export default CentralOfficeModalActions;
