/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import LayoutDash from "../../../components/Layouts/LayoutDash";
import TitleDash from "../../../components/TitleDash";
import {
  getAllJobsAdmin,
  GetJobsResponse,
} from "../../../shared/services/job.service";
import notification from "../../../ui/notification";
import { JobFormType } from "./hookforms.interfaces";
import { JobResolver } from "./Job.yup";
import JobModals from "./JobModals";
import JobsActions from "./JobsActions";
import JobsTable from "./JobsTable";

const Jobs = () => {
  const formMethods = useForm<JobFormType>({
    mode: "all",
    resolver: JobResolver,
    defaultValues: {
      id: 0,
      name: "",
      description: "",
      state: false,
      centralOfficeId: 0,
      workAreaId: 0,
      jobs: [],
      showModalJob: false,
      showModalWorkArea: false,
      showModalCentralOffice: false,
    },
  });
  const { mutate: getJobsMutate } = useMutation<GetJobsResponse, Error>(
    async (): Promise<GetJobsResponse> => {
      return await getAllJobsAdmin();
    },
    {
      onSuccess: ({ data }) => {
        formMethods.setValue("jobs", data);
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );
  useEffect(() => {
    getJobsMutate();
    return () => {};
  }, []);

  return (
    <FormProvider {...formMethods}>
      <LayoutDash
        title={<TitleDash name="puestos de trabajo" />}
        actions={<JobsActions />}
        table={<JobsTable />}
        modal={<JobModals />}
      />
    </FormProvider>
  );
};

export default Jobs;
