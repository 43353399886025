import React from "react";
import { useFormContext } from "react-hook-form";
import Table from "../../../../ui/Table";
import Column from "../../../../ui/Table/Column";
import { UserFormType } from "../hookforms.interfaces";
import UserRow from "./UserRow";

const UsersTable = () => {
  const { watch } = useFormContext<UserFormType>();

  const columns = (
    <tr>
      <Column align="left" width="5%" text="#"></Column>
      <Column align="left" width="30%" text="Nombre"></Column>
      <Column align="left" width="30%" text="Correo"></Column>
      <Column align="left" text="Rol" width="20%"></Column>
      <Column text="Estado" width="5%"></Column>
      <Column text="Acciones" width="10%"></Column>
    </tr>
  );

  const rows = watch("users").map((user, index) => {
    return <UserRow key={index + user.name} index={index} user={user} />;
  });

  return <Table columns={columns} count={rows.length} rows={rows}></Table>;
};

export default UsersTable;
