import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { WorkAreaType } from "../../../../../../shared/types/work-area.type";
import Container from "../../../../../../ui/Container";
import TextAreaField from "../../../../../../ui/fields/TextAreaField";
import Label from "../../../../../../ui/Label";

const WorkAreaModalInfo = () => {
  const {
    control,
    formState: { errors },
  } = useFormContext<WorkAreaType>();

  return (
    <Container width="100%" display="flex" flexDirection="column" gap="10px">
      <Container width="100%" display="flex" gap="10px">
        <Label label="Nombre: " />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextAreaField
              width="100%"
              rows={1}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.name}
            />
          )}
        />
      </Container>
    </Container>
  );
};

export default WorkAreaModalInfo;
