import React from "react";
import { useFormContext } from "react-hook-form";
import Modal from "../../../../ui/Modal";
import { UserFormType } from "../hookforms.interfaces";
import UserModal from "./UserModal";

const UsersModals = () => {
  const { watch, setValue } = useFormContext<UserFormType>();
  const onCloseModalUser = () => setValue("showModalUser", false);

  return (
    <>
      <Modal
        id="modal-user"
        title={watch("id") === 0 ? "Agregar Usuario" : "Editar Usuario"}
        contentOverflowY="auto"
        visible={watch("showModalUser")}
        onClose={onCloseModalUser}
      >
        <UserModal />
      </Modal>
    </>
  );
};

export default UsersModals;
