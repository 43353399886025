import { Route } from "react-router-dom";
import Home from "../../../pages/Home";
import DashLogin from "../../../pages/Dashboard/Login";
import ErrorPage from "../../../pages/ErrorPage";
import NotFound from "../../../pages/NotFound";
import AppSwitch from "../AppSwitch";
import GuestRoute from "../GhestRoutes";
import ProtectedRoutes from "../ProtectedRoutes";
import paths from "../paths";
import PostulacionPage from "../../../pages/Postulation";
import PublicRoutes from "../PublicRoutes";
import Users from "../../../pages/Dashboard/Users";
import Jobs from "../../../pages/Dashboard/Jobs";
import Solicitudes from "../../../pages/Dashboard/Solicitudes";
import AdminRoutes from "../AdminRoutes";

const AppRouter = () => {
  return (
    <AppSwitch>
      {/* Public Routes */}
      <Route element={<PublicRoutes pathname={paths.root} />}>
        <Route path={paths.root} element={<Home />} />
        <Route path={paths.postulacion} element={<PostulacionPage />} />
        <Route path={paths.error} element={<ErrorPage />} />
        <Route path={paths.general.notFound} element={<NotFound />} />
      </Route>

      {/* Auth Routes */}
      <Route element={<GuestRoute pathname={paths.dash.user.root} />}>
        <Route path={paths.dash.login} element={<DashLogin />} />
      </Route>

      {/* Admin Routes */}
      <Route element={<ProtectedRoutes pathname={paths.dash.login} />}>
        <Route element={<AdminRoutes pathname={paths.dash.solicitud.root} />}>
          <Route path={paths.dash.user.root} element={<Users />} />
        </Route>
        <Route path={paths.dash.solicitud.root} element={<Solicitudes />} />
        <Route path={paths.dash.job.root} element={<Jobs />} />
      </Route>

      <Route path="*" element={<NotFound />} />
    </AppSwitch>
  );
};

export default AppRouter;
