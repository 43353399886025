import { createContext, Dispatch, useContext, useState } from "react";
import { CentralOfficeType } from "../types/central-office.type";
import { RoleType } from "../types/role.type";
import { WorkAreaType } from "../types/work-area.type";

type AdminContextType = {
  workArea: {
    workAreas: Array<WorkAreaType>;
    setWorkAreas: Dispatch<Array<WorkAreaType>>;
  };
  centralOffice: {
    centralOffices: Array<CentralOfficeType>;
    setCentralOffices: Dispatch<Array<CentralOfficeType>>;
  };
  role: {
    roles: Array<RoleType>;
    setRoles: Dispatch<Array<RoleType>>;
  };
};

export const AdminContext = createContext({} as AdminContextType);

export const useAadminContext = () => {
  const context = useContext(AdminContext);

  if (context === null) {
    throw new Error("useAdminContext must be used within a AdminProvider");
  }

  return context;
};

export const AdminProvider = ({ children }: { children: JSX.Element }) => {
  const [centralOffices, setCentralOffices] = useState<CentralOfficeType[]>([]);
  const [workAreas, setWorkAreas] = useState<WorkAreaType[]>([]);
  const [roles, setRoles] = useState<RoleType[]>([]);

  return (
    <AdminContext.Provider
      value={{
        centralOffice: {
          centralOffices,
          setCentralOffices,
        },
        workArea: {
          workAreas,
          setWorkAreas,
        },
        role: {
          roles,
          setRoles,
        },
      }}
    >
      {children}
    </AdminContext.Provider>
  );
};
