import styled, { css } from "styled-components";
import Container from "../../ui/Container";

export const StyledLoginContainer = styled(Container)`
  ${({ theme }) => css`
    background-color: #fff;
    @media ${theme.device.tabletS} {
      .login__container {
        background-color: #fff;
        width: 430px;
        height: 90%;
        border: 2px solid ${theme.colors.Neutral4};
        border-radius: 12px;
        box-shadow: ${theme.shadows.elevationHigh};
      }
    }
  `}
`;
