import { Controller, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import styled, { css } from "styled-components";
import { device } from "../../../shared/breakpoints/reponsive";
import { useMediaQuery } from "../../../shared/hooks/useMediaQuery";
import { createPostulation } from "../../../shared/services/postulation.service";
import Button from "../../../ui/Button";
import Container from "../../../ui/Container";
import TextField from "../../../ui/fields/TextField";
import InputFile from "../../../ui/inputs/InputFile";
import notification from "../../../ui/notification";
import Select from "../../../ui/Select";
import { SelectItemType } from "../../../ui/Select/interfaces";
import Text from "../../../ui/Text";
import { PostulationFormType } from "../hookform.type";

const PostulationInfo = () => {
  const {
    getValues,
    control,
    handleSubmit,
    watch,
    setValue,
    resetField,
    formState: { errors },
  } = useFormContext<PostulationFormType>();
  const greaterThanDesktopS = useMediaQuery(device.desktopS);

  const optionsJob: Array<SelectItemType> = watch("jobs").map((job) => {
    return {
      key: String(job.id),
      label: job.name,
    };
  });

  const { isLoading: loadingPostulation, mutate: createPostulationMutation } =
    useMutation<any, Error>(
      async () => {
        const { id, jobs, ...restPostulation } = getValues();
        return await createPostulation(restPostulation);
      },
      {
        onSuccess: () => {
          onCleanFields();
          notification({ type: "success", message: "Postulatición enviada" });
        },
        onError: (error: any) => {
          notification({
            type: "error",
            message: error.response.data.message,
          });
        },
      }
    );

  const onCreatePostulation = () => {
    handleSubmit(() => {
      createPostulationMutation();
    })();
  };

  const onCleanFields = () => {
    resetField("address");
    resetField("age");
    resetField("email");
    resetField("gender");
    resetField("id");
    resetField("gender");
    resetField("jobId");
    resetField("lastname");
    resetField("name");
    setValue("cv", "");
    resetField("phone");
  };

  return (
    <Container
      display="flex"
      width="100%"
      alignItems="center"
      flexDirection="column"
      padding={greaterThanDesktopS ? "32px 100px" : "16px"}
    >
      <StyledTextTitle size="xl" weight="bold">
        LLENA TU FORMULARIO DE ADMISIÓN
      </StyledTextTitle>
      <Container
        width="100%"
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="center"
        gap="40px"
        padding="2rem"
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              label="Nombres"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
        <Controller
          name="lastname"
          control={control}
          render={({ field }) => (
            <TextField
              label="Apellidos"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.lastname}
              helperText={errors.lastname?.message}
            />
          )}
        />
        <Controller
          name="age"
          control={control}
          render={({ field }) => (
            <TextField
              label="Edad"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.age}
              helperText={errors.age?.message}
            />
          )}
        />
        <Controller
          name="gender"
          control={control}
          render={({ field }) => (
            <Select
              label="Género"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              options={[
                { key: "M", label: "M" },
                { key: "F", label: "F" },
              ]}
              onChange={(key) => {
                field.onChange(key);
              }}
              hasError={!!errors.gender}
              helperText={errors.gender?.message}
            />
          )}
        />
        <Controller
          name="phone"
          control={control}
          render={({ field }) => (
            <TextField
              label="Teléfono"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.phone}
              helperText={errors.phone?.message}
            />
          )}
        />
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              label="Dirección"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.address}
              helperText={errors.address?.message}
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              label="Correo Electrónico"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={field.value}
              onChange={field.onChange}
              hasError={!!errors.email}
              helperText={errors.email?.message}
            />
          )}
        />
        <Controller
          name="jobId"
          control={control}
          render={({ field }) => (
            <Select
              label="Puesto de Trabajo"
              width={greaterThanDesktopS ? "45%" : "100%"}
              value={String(field.value)}
              options={optionsJob}
              onChange={(key) => {
                field.onChange(key);
              }}
              hasError={!!errors.jobId}
              helperText={errors.jobId?.message}
            />
          )}
        />
        <Container width={greaterThanDesktopS ? "45%" : "100%"}>
          <InputFile
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              if (e.target.files && e.target.files.length > 0)
                setValue("cv", e.target.files[0]);
            }}
          />
        </Container>
        <Container width={greaterThanDesktopS ? "45%" : "100%"}></Container>
        <Container width={greaterThanDesktopS ? "45%" : "100%"}>
          <Button
            onClick={onCreatePostulation}
            disabled={loadingPostulation}
            loading={loadingPostulation}
            width="100%"
            label="Enviar Postulación"
            trailingIcon="ri-send-plane-line"
          />
        </Container>
        <Container width={greaterThanDesktopS ? "45%" : "100%"}></Container>
      </Container>
    </Container>
  );
};

export default PostulationInfo;

const StyledTextTitle = styled(Text.Title)`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    transition: all 500ms;
    text-transform: uppercase;
    padding: 0 16px;
    :hover {
      color: ${theme.colors.Secondary4};
    }
  `}
`;
