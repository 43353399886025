/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import LayoutDash from "../../../components/Layouts/LayoutDash";
import TitleDash from "../../../components/TitleDash";
import {
  getAllJobsAdmin,
  GetJobsResponse,
} from "../../../shared/services/job.service";
import {
  getAllPostulations,
  GetPostulationsResponse,
} from "../../../shared/services/postulation.service";
import notification from "../../../ui/notification";
import { PostulationFormType } from "./hookforms.interface";
import SolicitudesActions from "./SolicitudActions";
import SolicitudesTable from "./SolicitudesTable";
import SolicitudModals from "./SolicitudModals";

const Solicitudes = () => {
  const formMethods = useForm<PostulationFormType>({
    mode: "all",
    defaultValues: {
      postulations: [],
      showViewModal: false,
      showDeleteModal: false,
      description: "",
      postulation: {
        id: 0,
        address: "",
        age: 0,
        state: 0,
        email: "",
        gender: "",
        job: {
          id: 0,
          name: "",
          state: false,
          workAreaId: 0,
          centralOfficeId: 0,
        },
        lastname: "",
        name: "",
        phone: "",
        view: false,
        jobId: 0,
        cv: "",
      },
      search: {
        gender: "Todos",
        age: 0,
        jobId: 0,
        state: -1,
      },
      jobs: [],
    },
  });

  const { mutate: getPostulationsMutate } = useMutation<
    GetPostulationsResponse,
    Error
  >(
    async (): Promise<GetPostulationsResponse> => {
      return await getAllPostulations();
    },
    {
      onSuccess: ({ data }) => {
        formMethods.setValue("postulations", data);
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const { mutate: getJobsMutate } = useMutation<GetJobsResponse, Error>(
    async (): Promise<GetJobsResponse> => {
      return await getAllJobsAdmin();
    },
    {
      onSuccess: ({ data }) => {
        formMethods.setValue("jobs", data);
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  useEffect(() => {
    getPostulationsMutate();
    getJobsMutate();
    return () => {};
  }, []);

  return (
    <FormProvider {...formMethods}>
      <LayoutDash
        title={<TitleDash name="postulaciones" />}
        actions={<SolicitudesActions />}
        table={<SolicitudesTable />}
        modal={<SolicitudModals />}
      />
    </FormProvider>
  );
};

export default Solicitudes;
