import React from "react";
import styled, { css } from "styled-components";
import Text from "../../ui/Text";
type TitleDashProps = {
  name: string;
};

const TitleDash: React.FC<TitleDashProps> = ({ name }) => {
  return (
    <StyledTextTitle size="s" weight="bold">
      {name}
    </StyledTextTitle>
  );
};

export default TitleDash;

const StyledTextTitle = styled(Text.Title)`
  ${({ theme }) => css`
    transition: all 500ms;
    text-transform: uppercase;
    color: ${theme.colors.Primary1};
    :hover {
      color: ${theme.colors.Secondary6};
    }
  `}
`;
