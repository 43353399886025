// eslint-disable-next-line import/no-anonymous-default-export
export default {
  root: "/",
  error: "/error",
  postulacion: "/admision",
  general: {
    validate: "/validate-token",
    notFound: "/not-found",
  },
  dash: {
    root: "/dash",
    login: "/login",
    user: {
      root: "/dash/users",
    },
    solicitud: {
      root: "/dash/solicitud",
    },
    job: {
      root: "/dash/job",
    },
  },
};
