import { Outlet } from "react-router-dom";
import MenuDash from "../../../components/Menus/MenuDash";
import { useAcuapescaContext } from "../../contexts/AcuapescaProvider";
import { AdminProvider } from "../../contexts/AdminProvider";
import RedirectRoute from "../RedirectRoute";

type ProtectedRoutesProps = {
  pathname: string;
};

const ProtectedRoutes: React.FC<ProtectedRoutesProps> = ({ pathname }) => {
  //TODO: Get isAuthenticated from context - useGeneralContext

  const {
    auth: { isAuthenticated },
  } = useAcuapescaContext();

  // if (!isAuthenticated) {
  //   storage.clear();
  //   return <RedirectRoute pathname={pathname} />;
  // }

  if (!isAuthenticated) return <RedirectRoute pathname={pathname} />;

  return (
    <AdminProvider>
      <MenuDash>
        <Outlet />
      </MenuDash>
    </AdminProvider>
  );
};

export default ProtectedRoutes;
