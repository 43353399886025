import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { WorkAreaType } from "../../../../../shared/types/work-area.type";
import Container from "../../../../../ui/Container";
import { ModalWorkAreaResolver } from "./WorkArea.yup";
import WorkAreaModalActions from "./WorkAreaModalActions";
import WorkAreaModalInfo from "./WorkAreaModalInfo";
import WorkAreaModalTable from "./WorkAreaModalTable";

const WorkAreaModal = () => {
  const formMethods = useForm<WorkAreaType>({
    resolver: ModalWorkAreaResolver,
    mode: "all",
    defaultValues: {
      id: 0,
      name: "",
    },
  });
  return (
    <FormProvider {...formMethods}>
      <Container width="100%">
        <Container
          width="100%"
          padding="20px"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <WorkAreaModalActions />

          <WorkAreaModalInfo />

          <WorkAreaModalTable />
        </Container>
      </Container>
    </FormProvider>
  );
};

export default WorkAreaModal;
