import React, { FC } from "react";
import styled, { css } from "styled-components";
import { CentralOfficeType } from "../../../../shared/types/central-office.type";
import { JobType } from "../../../../shared/types/job.type";
import { WorkAreaType } from "../../../../shared/types/work-area.type";
import { DOMAIN } from "../../../../shared/utils/constant/api";
import Container from "../../../../ui/Container";
import Text from "../../../../ui/Text";

type JobCardProps = {
  job: JobType & { workArea: WorkAreaType; centralOffice: CentralOfficeType };
};

const JobCard: FC<JobCardProps> = (props) => {
  const {
    job: {
      name,
      description,
      centralOffice: { name: centralOfficeName },
      workArea: { name: workAreaName },
      img,
    },
  } = props;
  return (
    <StyledContainerCard
      minHeight="45rem"
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <StyledContainer
        width="90%"
        height="40rem"
        padding="2rem"
        display="flex"
        flexDirection="column"
        gap="10px"
      >
        <StyledTextTitle size="m" weight="bold">
          {name}
        </StyledTextTitle>
        <StyledTextBody size="m" weight="regular" color="Primary5">
          <Text.Body size="m" weight="bold">
            Sede:{" "}
          </Text.Body>{" "}
          {centralOfficeName}
        </StyledTextBody>
        <StyledTextBody
          className="container-area"
          size="m"
          weight="regular"
          color="Primary5"
        >
          <Text.Body size="m" weight="bold">
            Área de Trabajo:
          </Text.Body>{" "}
          {workAreaName}
        </StyledTextBody>
        <ContainerStyledDesc overFlowY="auto" height={img ? "70%" : "100%"}>
          {description.split("{enter}").map((item, i) => {
            return (
              <StyledTextDescription key={item + i} size="m" weight="regular">
                {item}
              </StyledTextDescription>
            );
          })}
        </ContainerStyledDesc>
        {img && (
          <Container width="100%" height="30%">
            <StyledImg src={`${DOMAIN}/img/${img}`} alt="" />
          </Container>
        )}
      </StyledContainer>
    </StyledContainerCard>
  );
};

export default JobCard;

const StyledImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const StyledContainerCard = styled(Container)`
  ${({ theme }) => css`
    padding: 0 15px;
    background-color: ${theme.colors.Primary4};
    border-radius: 10px;
  `}
`;

const ContainerStyledDesc = styled(Container)`
  ${({ theme }) => css`
    ::-webkit-scrollbar-thumb {
      background: ${theme.colors.Neutral5};
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: ${theme.colors.Neutral4};
    }
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar {
      width: 10px;
      background-color: transparent;
    }
  `}
`;

const StyledContainer = styled(Container)`
  ${({ theme }) => css`
    border-radius: 10px;
    background-color: #fff;
    transition: all 700ms;
    :hover {
      transform: scale(105%);
      box-shadow: 1px 10px 20px ${theme.colors.Secondary4};
    }

    .container-area {
      border-bottom: 1px solid ${theme.colors.Neutral5};
      padding-bottom: 15px;
    }
  `}
`;

const StyledTextDescription = styled(Text.Body)`
  /* width: 100%; */
  display: block;
`;

const StyledTextTitle = styled(Text.Title)`
  ${({ theme }) => css`
    display: block;
    transition: all 500ms;
    text-transform: uppercase;
    :hover {
      color: ${theme.colors.Secondary4};
    }
  `}
`;

const StyledTextBody = styled(Text.Body)`
  display: block;
  transition: all 500ms;
  text-transform: uppercase;
`;
