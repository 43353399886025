import React from "react";
import { useFormContext } from "react-hook-form";
import { DOMAIN } from "../../../../../shared/utils/constant/api";
import Button from "../../../../../ui/Button";
import Container from "../../../../../ui/Container";
import Text from "../../../../../ui/Text";
import { PostulationFormType } from "../../hookforms.interface";

const ViewSolicitudModal = () => {
  const { watch, setValue } = useFormContext<PostulationFormType>();

  const onApproved = () => {
    setValue("showSelectModal", true);
  };
  const onContratado = () => {
    setValue("showContratarModal", true);
  };

  return (
    <Container
      width="100%"
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Container
        width="100%"
        padding="20px"
        display="flex"
        flexDirection="column"
        gap="20px"
      >
        <Text.Body size="m" weight="regular">
          <b>Correo Electrónico: </b>
          {watch("postulation.email")}
        </Text.Body>
        <Text.Body size="m" weight="regular">
          <b>Dirección: </b>
          {watch("postulation.address")}
        </Text.Body>
        <Text.Body size="m" weight="regular">
          <b>Género: </b>
          {watch("postulation.gender")}
        </Text.Body>
        <Text.Body size="m" weight="regular">
          <b>Puesto de Trabajo: </b>
          {watch("postulation.job.name")}
        </Text.Body>
        <Text.Body size="m" weight="regular">
          <b>Teléfono: </b>
          {watch("postulation.phone")}
        </Text.Body>
        <Text.Body size="m" weight="regular">
          <b>Edad: </b>
          {watch("postulation.age")}
        </Text.Body>
        <Text.Body size="m" weight="regular">
          {watch("postulation.cv")?.toString() !== "" && (
            <>
              <b>CV: </b>
              <a
                download={watch("postulation.cv")
                  ?.toString()
                  .slice(36, watch("postulation.cv")?.toString().length)}
                href={`${DOMAIN}/docs/${watch("postulation.cv")?.toString()}`}
              >
                {watch("postulation.cv")
                  ?.toString()
                  .slice(36, watch("postulation.cv")?.toString().length)}
              </a>
            </>
          )}
        </Text.Body>
      </Container>
      <Container
        display="flex"
        gap="10px"
        justifyContent="flex-end"
        flexDirection="row"
        width="100%"
        padding="2rem"
      >
        <Button
          onClick={onApproved}
          label={"Seleccionar"}
          display="default"
          trailingIcon="ri-check-line"
        />
        <Button
          onClick={onContratado}
          label={"Contratar"}
          display="warning"
          trailingIcon="ri-check-line"
        />
      </Container>
    </Container>
  );
};

export default ViewSolicitudModal;
