import { AxiosResponse } from "axios";
import { CentralOfficeType } from "../types/central-office.type";
import { JobType } from "../types/job.type";
import { WorkAreaType } from "../types/work-area.type";
import axiosClient from "../utils/api/clientAxios";

type GetJobsApi = Array<
  JobType & { workArea: WorkAreaType; centralOffice: CentralOfficeType }
>;
type CreateJobApi = JobType & {
  workArea: WorkAreaType;
  centralOffice: CentralOfficeType;
};
type EditJobApi = JobType & {
  workArea: WorkAreaType;
  centralOffice: CentralOfficeType;
};
type ChangeJobApi = JobType & {
  workArea: WorkAreaType;
  centralOffice: CentralOfficeType;
};
type DeleteJobApi = { id: number };

export type GetJobsResponse = AxiosResponse<GetJobsApi, GetJobsApi>;
export type CreateJobResponse = AxiosResponse<CreateJobApi, CreateJobApi>;
export type EditJobResponse = AxiosResponse<EditJobApi, EditJobApi>;
export type ChangeJobResponse = AxiosResponse<ChangeJobApi, ChangeJobApi>;
export type DeleteJobResponse = AxiosResponse<DeleteJobApi, DeleteJobApi>;

const API = axiosClient.getUri();

const url = `${API}/job`;

/**
 * In this method we get all active Jobs from the API
 * @returns {Array<JobType>}
 */
export const getAllJobs = async (): Promise<GetJobsResponse> => {
  return await axiosClient.get(`${url}`);
};
/**
 * In this method we get all Jobs from the API
 * @returns {Array<JobType>}
 */
export const getAllJobsAdmin = async (): Promise<GetJobsResponse> => {
  return await axiosClient.get(`${url}/admin`);
};

/**
 * In this method we create a new Job from the API
 * @param {JobType} job
 * @returns
 */
export const createJob = async (
  job: Omit<JobType, "id" | "state">
): Promise<CreateJobResponse> => {
  const formData = new FormData();
  if (job.img === "") delete job.img;
  formData.append("name", job.name);
  formData.append("description", job.description);
  if (job.img) formData.append("img", job.img);
  formData.append("workAreaId", job.workAreaId + "");
  formData.append("centralOfficeId", job.centralOfficeId + "");
  return await axiosClient.post(`${url}`, job, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * In this method we edit a Job by Id from the API
 * @param {number} id Job's id
 * @param {JobType} job
 * @returns
 */
export const editJob = async (
  id: number,
  job: Omit<JobType, "id" | "state">
): Promise<EditJobResponse> => {
  const formData = new FormData();
  if (job.img === "") delete job.img;
  formData.append("name", job.name);
  formData.append("description", job.description);
  if (job.img) formData.append("img", job.img);
  formData.append("workAreaId", job.workAreaId + "");
  formData.append("centralOfficeId", job.centralOfficeId + "");
  return await axiosClient.put(`${url}/${id}`, job, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * In this method we chage a Job's attribute by Id from the API
 * @param {number} id Job's id
 * @param {JobType} job
 * @returns
 */
export const changeJob = async (
  id: number,
  job: Omit<
    JobType,
    "id" | "name" | "description" | "workAreaId" | "centralOfficeId" | "img"
  >
): Promise<ChangeJobResponse> => {
  return await axiosClient.patch(`${url}/${id}`, job);
};

/**
 * In this methods we delete a Job by Id from the API
 * @param {number} id Job's id
 * @returns
 */
export const deleteJob = async (id: number): Promise<DeleteJobResponse> => {
  return await axiosClient.delete(`${url}/${id}`);
};
