import { AxiosResponse } from "axios";
import { LoginType } from "../types/login.type";
import { RoleType } from "../types/role.type";
import { UserType } from "../types/user.type";
import axiosClient from "../utils/api/clientAxios";

export type LoginAPI = {
  user: UserType & { role: RoleType };
  token: string;
  message: string;
};

export type ResponseLogin = AxiosResponse<LoginAPI, LoginAPI>;

const API = axiosClient.getUri();

const url = `${API}/auth`;

/**
 * This is the endpoint /auth/signin
 * @param {LoginType} login
 * @returns {Promise<ResponseLogin>}
 */
export const loginService = async (
  login: LoginType
): Promise<ResponseLogin> => {
  return await axiosClient.post(`${url}/signin`, login);
};
