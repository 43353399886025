import paths from "../../../../shared/routes/paths";

export const getMenuItems = () => {
  return [
    {
      id: 1,
      title: "USUARIOS",
      remixClass: "ri-user-6-fill",
      path: paths.dash.user.root,
      role: "Administrador",
    },
    {
      id: 2,
      title: "POSTULACIONES",
      remixClass: "ri-group-fill",
      path: paths.dash.solicitud.root,
      role: "Gestor",
    },
    {
      id: 3,
      title: "PUESTOS",
      remixClass: "ri-group-fill",
      path: paths.dash.job.root,
      role: "Gestor",
    },
  ];
};
