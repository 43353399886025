import { AxiosResponse } from "axios";
import { RoleType } from "../types/role.type";
import { UserType } from "../types/user.type";
import axiosClient from "../utils/api/clientAxios";

type GetUsersApi = Array<UserType & { role: RoleType }>;
type CreateUserApi = UserType & { role: RoleType };
type EditUserApi = UserType & { role: RoleType };
type ChangeUserApi = UserType & { role: RoleType };
type DeleteUserApi = { id: number };

export type GetUsersResponse = AxiosResponse<GetUsersApi, GetUsersApi>;
export type CreateUserResponse = AxiosResponse<CreateUserApi, CreateUserApi>;
export type EditUserResponse = AxiosResponse<EditUserApi, EditUserApi>;
export type ChangeUserResponse = AxiosResponse<ChangeUserApi, ChangeUserApi>;
export type DeleteUserResponse = AxiosResponse<DeleteUserApi, DeleteUserApi>;

const API = axiosClient.getUri();

const url = `${API}/user`;

/**
 * In this method we get all users from the API
 * @returns {Promise<Array<UserType>}
 */
export const getAllUser = async (): Promise<GetUsersResponse> => {
  return await axiosClient.get(`${url}`);
};

/**
 * In this method we create a user from the API
 * @param {UserType} user
 * @returns {Promise<Array<UserType>}
 */
export const createUser = async (
  user: Omit<UserType, "id" | "state">
): Promise<CreateUserResponse> => {
  return await axiosClient.post(`${url}`, user);
};

/**
 * In this method we edit a user by id from the API
 * @param {number} id user's id
 * @param {UserType} user
 * @returns {UserType}
 */
export const editUser = async (
  id: number,
  user: Omit<UserType, "id" | "state">
): Promise<EditUserResponse> => {
  return await axiosClient.put(`${url}/${id}`, user);
};

/**
 * In this method we update just an attribute
 * @param id user's id
 * @param user
 * @returns {UserType}
 */
export const changeUser = async (
  id: number,
  user: Omit<UserType, "id" | "name" | "password" | "email" | "roleId">
): Promise<ChangeUserResponse> => {
  return await axiosClient.patch(`${url}/${id}`, user);
};

/**
 * In this method we delete a user by id from the API
 * @param id user's id
 * @returns {number}
 */
export const deleteUser = async (id: number): Promise<DeleteUserResponse> => {
  return await axiosClient.delete(`${url}/${id}`);
};
