import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { useAadminContext } from "../../../../../../shared/contexts/AdminProvider";
import {
  createWorkArea,
  deleteWorkArea,
  editWorkArea,
} from "../../../../../../shared/services/work-area.service";
import { WorkAreaType } from "../../../../../../shared/types/work-area.type";
import Button from "../../../../../../ui/Button";
import Container from "../../../../../../ui/Container";
import notification from "../../../../../../ui/notification";

const WorkAreaModalActions = () => {
  const { setValue, getValues, handleSubmit, watch } =
    useFormContext<WorkAreaType>();

  const {
    workArea: { setWorkAreas, workAreas },
  } = useAadminContext();

  const { isLoading: loadingCreateWorkArea, mutate: createWorkAreaMutate } =
    useMutation<any, Error>(
      async () => {
        const { id, ...restWorkArea } = getValues();
        return await createWorkArea(restWorkArea);
      },
      {
        onSuccess: ({ data }) => {
          setWorkAreas([...workAreas, data]);
          onCleanFields();
          notification({ type: "success", message: "Área de Trabajo creada" });
        },
        onError: (error: any) => {
          notification({
            type: "error",
            message: error.response.data.message,
          });
        },
      }
    );

  const { isLoading: loadingEditWorkArea, mutate: editWorkAreaMutate } =
    useMutation<any, Error>(
      async () => {
        const { id, ...restWorkArea } = getValues();
        return await editWorkArea(id, restWorkArea);
      },
      {
        onSuccess: ({ data }) => {
          setWorkAreas(
            workAreas.map((workArea) => {
              if (workArea.id === Number(data.id)) return data;
              return workArea;
            })
          );
          onCleanFields();
          notification({ type: "success", message: "Área de Trabajo editada" });
        },
        onError: (error: any) => {
          notification({
            type: "error",
            message: error.response.data.message,
          });
        },
      }
    );

  const { isLoading: loadingDeleteWorkArea, mutate: deleteWorkAreaMutate } =
    useMutation<any, Error>(
      async () => {
        const { id } = getValues();
        return await deleteWorkArea(id);
      },
      {
        onSuccess: ({ data }) => {
          setWorkAreas(workAreas.filter((workArea) => workArea.id !== data.id));
          onCleanFields();
          notification({
            type: "success",
            message: "Área de Trabajo eliminada",
          });
        },
        onError: (error: any) => {
          if (error.response.data.message.search("foreign key") !== -1) {
            notification({
              type: "error",
              message:
                "Hay Puestos de Trabajo asociados a esta Área de Trabajo, no se puede eliminar",
            });
            return;
          }
          notification({
            type: "error",
            message: error.response.data.message,
          });
        },
      }
    );

  const onCleanFields = () => {
    setValue("id", 0);
    setValue("name", "");
  };

  const onAddWorkArea = () => {
    handleSubmit(() => {
      createWorkAreaMutate();
    })();
  };
  const onEditWorkArea = () => {
    handleSubmit(() => {
      editWorkAreaMutate();
    })();
  };
  const onDeleteWorkArea = () => {
    handleSubmit(() => {
      deleteWorkAreaMutate();
    })();
  };

  return (
    <Container
      width="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      gap="20px"
    >
      <Button
        width="100px"
        shape="round"
        trailingIcon="ri-add-fill"
        disabled={watch("id") !== 0}
        onClick={onAddWorkArea}
        loading={loadingCreateWorkArea}
      />
      <Button
        width="100px"
        shape="round"
        trailingIcon="ri-edit-2-line"
        disabled={watch("id") === 0}
        onClick={onEditWorkArea}
        loading={loadingEditWorkArea}
      />
      <Button
        width="100px"
        shape="round"
        display="danger"
        trailingIcon="ri-close-line"
        disabled={watch("id") === 0}
        onClick={onDeleteWorkArea}
        loading={loadingDeleteWorkArea}
      />
      <Button
        width="100px"
        shape="round"
        display="warning"
        trailingIcon="ri-brush-2-line"
        onClick={onCleanFields}
      />
    </Container>
  );
};

export default WorkAreaModalActions;
