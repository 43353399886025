/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { device } from "../../../shared/breakpoints/reponsive";
import { useAcuapescaContext } from "../../../shared/contexts/AcuapescaProvider";
import { useAadminContext } from "../../../shared/contexts/AdminProvider";
import { useMediaQuery } from "../../../shared/hooks/useMediaQuery";
import paths from "../../../shared/routes/paths";
import { getAllCentralOffices } from "../../../shared/services/central-office.service";
import { getAllRoles } from "../../../shared/services/role.service";
import { getAllWorkAreas } from "../../../shared/services/work-area.service";
import storage from "../../../shared/utils/storage";
import Container from "../../../ui/Container";
import Icon from "../../../ui/Icon";
import Text from "../../../ui/Text";
import { getMenuItems } from "./utils/get-menu-items";

const MenuCompany = ({ children }: { children: JSX.Element }) => {
  const [toggleMenu, setToggleMenu] = useState(false);

  // General Context
  const {
    auth: { setIsAuthenticated },
    user: { user },
  } = useAcuapescaContext();

  const {
    centralOffice: { setCentralOffices },
    workArea: { setWorkAreas },
    role: { setRoles },
  } = useAadminContext();

  const greaterThanTabletL = useMediaQuery(device.tabletL);

  const items = getMenuItems();

  const onClickToggle = () => {
    if (!greaterThanTabletL) {
      setToggleMenu(!toggleMenu);
    }
  };

  // Log Out
  const logOut = () => {
    storage.clear();
    setIsAuthenticated(false);
  };

  // Get Work Areas
  const { refetch: refetchAreas } = useQuery(
    "query-get-all-work-areas",
    async () => {
      return await getAllWorkAreas();
    },
    {
      onSuccess: ({ data }) => {
        setWorkAreas(data);
      },
    }
  );
  const { refetch: refectRoles } = useQuery(
    "query-get-all-roles",
    async () => {
      return await getAllRoles();
    },
    {
      onSuccess: ({ data }) => {
        setRoles(data);
      },
    }
  );

  // Get Central Offices
  const { refetch: refetchCentralOffices } = useQuery(
    "query-get-all-central-offices",
    async () => {
      return await getAllCentralOffices();
    },
    {
      onSuccess: ({ data }) => {
        setCentralOffices(data);
      },
    }
  );

  useEffect(() => {
    refectRoles();
    refetchAreas();
    refetchCentralOffices();
  }, []);

  return (
    <StyledMenu
      width="100%"
      display="flex"
      flexDirection="column"
      position="relative"
    >
      {/* Title */}
      <Container
        className="layout__header"
        width="100%"
        height="50px"
        display="flex"
        alignItems="center"
        gap="35px"
        padding="15px"
      >
        <Icon remixClass="ri-menu-line" size={30} onClick={onClickToggle} />

        <Text.Body size="l" weight="bold" ellipsis>
          {/* {user.name} - {user.role.name} */}
          {user.name}
        </Text.Body>
      </Container>

      {/* Main */}
      <Container
        width="100%"
        height="calc(100vh - 50px)"
        display="flex"
        flexDirection="row"
      >
        {/* Sidebar */}
        <Container
          className={`layout__menu ${
            !greaterThanTabletL && !toggleMenu && "hide-component"
          }`}
          width="100%"
        >
          {/* Sidebar Items */}
          <ul className="nav">
            {items.map((item) => {
              if (
                item.role === user.role.name ||
                user.role.name === "Administrador"
              ) {
                return (
                  <Link
                    key={item.id}
                    to={item.path}
                    className="nav__items"
                    onClick={onClickToggle}
                  >
                    <Icon remixClass={item.remixClass} />
                    <Text.Body size="m" weight="bold" color="Neutral0">
                      {item.title}
                    </Text.Body>
                  </Link>
                );
              }
              return <></>;
            })}
            <Link to={paths.dash.login} className="nav__items" onClick={logOut}>
              <Icon remixClass="ri-logout-circle-line" />
              <Text.Body size="m" weight="bold" color="Neutral0">
                CERRAR SESIÓN
              </Text.Body>
            </Link>
          </ul>
        </Container>

        {/* Content */}
        <Container
          className={`layout__content ${toggleMenu && "hide-component"}`}
          width="100%"
          height="100%"
        >
          {children}
        </Container>
      </Container>
    </StyledMenu>
  );
};

export default MenuCompany;

const StyledMenu = styled(Container)`
  ${({ theme }) => css`
    .layout__header {
      box-shadow: ${theme.shadows.elevationMedium};
    }

    .layout__menu {
      transition: width 0.3s ease;
      background-color: ${theme.colors.Primary5};
      top: 50px;

      .nav {
        height: calc(100vh - 50px);
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        list-style-type: none;
        color: ${theme.colors.Neutral0};

        .nav__items {
          cursor: pointer;
          width: 100%;
          height: 60px;
          padding: 0 18px;
          display: flex;
          align-items: center;
          gap: 18px;

          :hover {
            background-color: ${theme.colors.Neutral5};
          }
        }
      }
    }

    @media ${theme.device.tabletL} {
      .layout__menu {
        width: 60px;
        position: static;

        &:hover {
          width: 200px;
        }
      }
    }
  `}
`;
