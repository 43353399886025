import { ThemeProvider } from "styled-components";
import { BrowserRouter } from "react-router-dom";
import GlobalStyles from "../../styles/GlobalStyles";
import initialTheme from "../../styles/theme";
import { QueryClient, QueryClientProvider } from "react-query";
import { AcuapescaProvider } from "./AcuapescaProvider";
import { Toaster } from "react-hot-toast";

interface ProvidersProps {
  children: React.ReactNode;
}

export const Providers: React.FC<ProvidersProps> = ({ children }) => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        retry: false,
        staleTime: 5 * 60 * 1000,
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <AcuapescaProvider>
          <ThemeProvider theme={initialTheme}>
            <GlobalStyles />
            <Toaster />
            {children}
          </ThemeProvider>
        </AcuapescaProvider>
      </BrowserRouter>
    </QueryClientProvider>
  );
};
