/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import LayoutDash from "../../../components/Layouts/LayoutDash";
import TitleDash from "../../../components/TitleDash";
import {
  getAllUser,
  GetUsersResponse,
} from "../../../shared/services/user.service";
import notification from "../../../ui/notification";
import { UserFormType } from "./hookforms.interfaces";
import { UserResolver } from "./Users,yup";
import UsersActions from "./UsersActions";
import UsersModals from "./UsersModals";
import UsersTable from "./UsersTable";

const Users = () => {
  const formMethods = useForm<UserFormType>({
    mode: "all",
    resolver: UserResolver,
    defaultValues: {
      email: "",
      id: 0,
      name: "",
      password: "",
      roleId: 0,
      state: true,
      users: [],
      showModalUser: false,
    },
  });

  const { mutate: getUsersMutate } = useMutation<GetUsersResponse, Error>(
    async (): Promise<GetUsersResponse> => {
      return await getAllUser();
    },
    {
      onSuccess: ({ data }) => {
        formMethods.setValue("users", data);
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  useEffect(() => {
    getUsersMutate();
    return () => {};
  }, []);

  return (
    <FormProvider {...formMethods}>
      <LayoutDash
        title={<TitleDash name="usuarios" />}
        actions={<UsersActions />}
        table={<UsersTable />}
        modal={<UsersModals />}
      />
    </FormProvider>
  );
};

export default Users;
