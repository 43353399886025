import { useEffect } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { getAllJobs, GetJobsResponse } from "../../shared/services/job.service";
import Container from "../../ui/Container";
import notification from "../../ui/notification";
import { PostulationFormType } from "./hookform.type";
import JobList from "./JobList";
import { PostulationResolver } from "./Postulation.yup";
import PostulationInfo from "./PostulationInfo";

const PostulacionPage = () => {
  const formMethods = useForm<PostulationFormType>({
    resolver: PostulationResolver,
    defaultValues: {
      id: 0,
      name: "",
      lastname: "",
      age: 0,
      email: "",
      address: "",
      gender: "",
      phone: "",
      jobId: 0,
      cv: null,
      jobs: [],
    },
  });
  const { mutate: getJobsMutate } = useMutation<GetJobsResponse, Error>(
    async (): Promise<GetJobsResponse> => {
      return await getAllJobs();
    },
    {
      onSuccess: ({ data }) => {
        formMethods.setValue(
          "jobs",
          data.map((item) => {
            return {
              ...item,
              description: item.description.replaceAll("\r\n", "{enter}"),
            };
          })
        );
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );
  useEffect(() => {
    getJobsMutate();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <FormProvider {...formMethods}>
      <Container
        width="100%"
        // minHeight="calc(100vh - 9.5rem)"
        display="flex"
        flexDirection="column"
        gap="2rem"
      >
        <JobList />
        <PostulationInfo />
      </Container>
    </FormProvider>
  );
};

export default PostulacionPage;
