import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import styled, { css } from "styled-components";
import Button from "../../../../../ui/Button";
import Container from "../../../../../ui/Container";
import notification from "../../../../../ui/notification";
import Row from "../../../../../ui/Table/Row";
import { JobFormType } from "../../hookforms.interfaces";
import Switch from "../../../../../ui/Switch";
import { JobType } from "../../../../../shared/types/job.type";
import { CentralOfficeType } from "../../../../../shared/types/central-office.type";
import { WorkAreaType } from "../../../../../shared/types/work-area.type";
import {
  changeJob,
  ChangeJobResponse,
  deleteJob,
  DeleteJobResponse,
} from "../../../../../shared/services/job.service";

type JobRowProps = {
  index: number;
  job: JobType & { workArea: WorkAreaType; centralOffice: CentralOfficeType };
};

const JobRow: React.FC<JobRowProps> = (props) => {
  const { watch, setValue } = useFormContext<JobFormType>();

  const {
    job: {
      id,
      state,
      description,
      centralOfficeId,
      workAreaId,
      name,
      centralOffice: { name: centralOfficeName },
      workArea: { name: workAreaName },
    },
    index,
  } = props;

  const { mutate: changeJobMutate } = useMutation<ChangeJobResponse, Error>(
    async (): Promise<ChangeJobResponse> => {
      return await changeJob(id, { state: !state });
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "jobs",
          watch("jobs").map((job) => {
            if (job.id === data.id) return data;
            return job;
          })
        );
        notification({
          type: "success",
          message: `Puesto de trabajo ${data.name} ${
            data.state ? "Habilitado" : "Inhabilitado"
          }`,
        });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const { mutate: deleteJobMutate } = useMutation<DeleteJobResponse, Error>(
    async (): Promise<DeleteJobResponse> => {
      return await deleteJob(id);
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "jobs",
          watch("jobs").filter((job) => job.id !== data.id)
        );
        notification({
          type: "success",
          message: "Puesto de trabajo eliminado",
        });
      },
      onError: (error: any) => {
        if (error.response.data.message.search("foreign key") !== -1) {
          notification({
            type: "error",
            message:
              "Hay postulaciones asociadas a este Puesto de Trabajo, no se puede eliminar",
          });
          return;
        }
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const handleChangeJob = () => {
    changeJobMutate();
  };

  const handleDeleteButton = () => {
    deleteJobMutate();
  };

  const handleEditButton = () => {
    setValue("id", id);
    setValue("description", description);
    setValue("name", name);
    setValue("workAreaId", workAreaId);
    setValue("centralOfficeId", centralOfficeId);
    setValue("state", state);
    setValue("showModalJob", true);
  };

  return (
    <StyledTr index={index}>
      <Row>{index + 1}</Row>
      <Row>{name}</Row>
      <Row>{workAreaName}</Row>
      <Row>{centralOfficeName}</Row>
      <Row center>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Switch onChange={handleChangeJob} value={state}></Switch>
        </Container>
      </Row>
      <Row center>
        <Container
          padding="0.5rem 0"
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Button
            onClick={handleEditButton}
            shape="round"
            display="warning"
            trailingIcon="ri-edit-fill"
          ></Button>
          <Button
            onClick={handleDeleteButton}
            shape="round"
            display="danger"
            trailingIcon="ri-close-line"
          ></Button>
        </Container>
      </Row>
    </StyledTr>
  );
};

export default JobRow;

const StyledTr = styled.tr<{ index: number }>`
  ${({ theme, index }) => css`
    background-color: ${index % 2 === 0 ? `${theme.colors.Neutral3}` : `#fff`};
    :hover {
      background-color: ${theme.colors.Neutral4};
    }
  `}
`;
