import { yupResolver } from "@hookform/resolvers/yup";
import { PostulationType } from "../../shared/types/postulation.type";
import yup from "../../shared/yupLocale";

const PostulationSchema: yup.SchemaOf<
  Omit<PostulationType, "id" | "view" | "state" | "cv">
> = yup.object().shape({
  name: yup.string().required("Este campo es requerido"),
  lastname: yup.string().required("Este campo es requerido"),
  address: yup.string().required("Este campo es requerido"),
  age: yup
    .number()
    .min(1, "Este campo es requerido")
    .required("Este campo es requerido"),

  email: yup
    .string()
    .email("No cumple el formato")
    .required("Este campo es requerido"),
  gender: yup.string().required("Este campo es requerido"),
  phone: yup.string().required("Este campo es requerido"),
  jobId: yup
    .number()
    .min(1, "Este campo es requerido")
    .required("Este campo es requerido"),
});

export const PostulationResolver = yupResolver(PostulationSchema);
