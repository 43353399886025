import React from "react";
import { useFormContext } from "react-hook-form";
import Modal from "../../../../ui/Modal";
import { JobFormType } from "../hookforms.interfaces";
import CentralOfficeModal from "./CentralOfficeModal";
import JobModal from "./JobModal";
import WorkAreaModal from "./WorkAreaModal";

const JobModals = () => {
  const { watch, setValue } = useFormContext<JobFormType>();

  const onCloseModalJob = () => setValue("showModalJob", false);
  const onCloseModalWorkArea = () => setValue("showModalWorkArea", false);
  const onCloseModalCentralOffice = () =>
    setValue("showModalCentralOffice", false);

  return (
    <>
      <Modal
        id="modal-job"
        title={
          watch("id") === 0
            ? "Agregar Puesto de trabajo"
            : "Editar Puesto de trabajo"
        }
        contentOverflowY="auto"
        visible={watch("showModalJob")}
        onClose={onCloseModalJob}
      >
        <JobModal />
      </Modal>
      <Modal
        id="modal-work-area"
        title="Áreas de trabajo"
        contentOverflowY="auto"
        visible={watch("showModalWorkArea")}
        onClose={onCloseModalWorkArea}
      >
        <WorkAreaModal />
      </Modal>
      <Modal
        id="modal-central-office"
        title="Sedes"
        contentOverflowY="auto"
        visible={watch("showModalCentralOffice")}
        onClose={onCloseModalCentralOffice}
      >
        <CentralOfficeModal />
      </Modal>
    </>
  );
};

export default JobModals;
