import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import styled, { css } from "styled-components";
import {
  changeUser,
  ChangeUserResponse,
  deleteUser,
  DeleteUserResponse,
} from "../../../../../shared/services/user.service";
import { RoleType } from "../../../../../shared/types/role.type";
import { UserType } from "../../../../../shared/types/user.type";
import Button from "../../../../../ui/Button";
import Container from "../../../../../ui/Container";
import notification from "../../../../../ui/notification";
import Switch from "../../../../../ui/Switch";
import Row from "../../../../../ui/Table/Row";
import { UserFormType } from "../../hookforms.interfaces";

type UserRowProps = {
  index: number;
  user: UserType & { role: RoleType };
};

const UserRow: React.FC<UserRowProps> = (props) => {
  const { watch, setValue } = useFormContext<UserFormType>();
  const {
    user: {
      id,
      password,
      state,
      name: userName,
      email,
      role: { name: roleName },
      roleId,
    },
    index,
  } = props;

  const { mutate: changeUserMutate } = useMutation<ChangeUserResponse, Error>(
    async (): Promise<ChangeUserResponse> => {
      return await changeUser(id, { state: !state });
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "users",
          watch("users").map((user) => {
            if (user.id === data.id) return data;
            return user;
          })
        );
        notification({
          type: "success",
          message: `Usuario ${data.name} ${
            data.state ? "Habilitado" : "Inhabilitado"
          }`,
        });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const { mutate: deleteUserMutate } = useMutation<DeleteUserResponse, Error>(
    async (): Promise<DeleteUserResponse> => {
      return await deleteUser(id);
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "users",
          watch("users").filter((user) => user.id !== data.id)
        );
        notification({
          type: "success",
          message: "Usuario eliminado",
        });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const handleChangeUser = () => {
    changeUserMutate();
  };

  const handleDeleteButton = () => {
    deleteUserMutate();
  };

  const handleEditButton = () => {
    setValue("id", id);
    setValue("email", email);
    setValue("name", userName);
    setValue("roleId", roleId);
    setValue("password", password);
    setValue("state", state);
    setValue("showModalUser", true);
  };

  return (
    <StyledTr index={index}>
      <Row>{index + 1}</Row>
      <Row>{userName}</Row>
      <Row>{email}</Row>
      <Row>{roleName}</Row>
      <Row center>
        <Container
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Switch onChange={handleChangeUser} value={state}></Switch>
        </Container>
      </Row>
      <Row center>
        <Container
          padding="0.5rem 0"
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
        >
          <Button
            onClick={handleEditButton}
            shape="round"
            display="warning"
            trailingIcon="ri-edit-fill"
          ></Button>
          <Button
            onClick={handleDeleteButton}
            shape="round"
            display="danger"
            trailingIcon="ri-close-line"
          ></Button>
        </Container>
      </Row>
    </StyledTr>
  );
};

export default UserRow;

const StyledTr = styled.tr<{ index: number }>`
  ${({ theme, index }) => css`
    background-color: ${index % 2 === 0 ? `${theme.colors.Neutral3}` : `#fff`};
    :hover {
      background-color: ${theme.colors.Neutral4};
    }
  `}
`;
