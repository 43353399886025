/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import Container from "../../../ui/Container";
import JobCard from "./JobCard";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import styled, { css } from "styled-components";
import { useMediaQuery } from "../../../shared/hooks/useMediaQuery";
import { device } from "../../../shared/breakpoints/reponsive";
import { useFormContext } from "react-hook-form";
import { JobFormType } from "../../Dashboard/Jobs/hookforms.interfaces";
import Text from "../../../ui/Text";

const JobList = () => {
  const { watch } = useFormContext<JobFormType>();
  const greaterThanDesktopS = useMediaQuery(device.desktopS);
  const greaterThanTabletL = useMediaQuery(device.tabletL);

  return (
    <Container
      width="100%"
      display="flex"
      justifyContent="space-around"
      flexWrap="wrap"
      gap="10px"
      padding="2rem 0rem"
    >
      <StyledContainerTitle width="100%" display="flex" padding="0 20px">
        <StyledTextTitle size="l" weight="bold">
          PUESTOS DISPONIBLES
        </StyledTextTitle>
      </StyledContainerTitle>

      <ContainerSplide
        options={{
          arrows: true,
          autoplay: true,
          speed: 300,
          perPage: greaterThanDesktopS ? 3 : greaterThanTabletL ? 2 : 1,
          gap: 15,
        }}
      >
        {watch("jobs").map((job) => {
          return (
            <SplideSlide key={job.id + job.name}>
              <JobCard job={job} />
            </SplideSlide>
          );
        })}
      </ContainerSplide>
    </Container>
  );
};

export default JobList;

const ContainerSplide = styled(Splide)`
  padding: 0;
  width: 100%;
  padding: 0 30px;

  ${({ theme }) => css`
    .splide__arrow {
      width: 50px;
      height: 50px;
      border-radius: 10px;
      border: 1px solid ${theme.colors.Secondary5};
      background-color: ${theme.colors.Neutral0};
      opacity: 1;

      svg {
        fill: ${theme.colors.Secondary5} !important;
      }

      svg:hover {
        fill: ${theme.colors.Secondary5} !important;
      }
    }

    .splite__arrow:hover {
      opacity: 1;
    }
  `}
`;

const StyledContainerTitle = styled(Container)`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.colors.Neutral5};
    margin-bottom: 20px;
  `}
`;

const StyledTextTitle = styled(Text.Title)`
  ${({ theme }) => css`
    display: block;
    text-align: center;
    transition: all 500ms;
    text-transform: uppercase;
    padding: 0 16px;
    padding-bottom: 10px;

    :hover {
      color: ${theme.colors.Secondary4};
    }
  `}
`;
