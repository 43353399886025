import React from "react";
import { useFormContext } from "react-hook-form";
import Modal from "../../../../ui/Modal";
import { PostulationFormType } from "../hookforms.interface";
import ConfirmContratarModal from "./ConfirmContratarModal";
import ConfirmDeleteModal from "./ConfirmDeleteModal";
import ConfirmSelectModal from "./ConfirmSelectModal";
import ViewSolicitudModal from "./ViewSolicitudModal";

const SolicitudModals = () => {
  const { watch, setValue } = useFormContext<PostulationFormType>();

  const onCloseModalView = () => setValue("showViewModal", false);
  const onCloseModalDelete = () => setValue("showDeleteModal", false);
  const onCloseModalContratar = () => setValue("showContratarModal", false);
  const onCloseModalSelect = () => setValue("showSelectModal", false);
  return (
    <>
      <Modal
        id="modal-view-postulation"
        title={`${watch("postulation.name")} ${watch("postulation.lastname")}`}
        contentOverflowY="auto"
        visible={watch("showViewModal")}
        onClose={onCloseModalView}
      >
        <ViewSolicitudModal />
      </Modal>
      <Modal
        size="small"
        id="modal-delete-postulation"
        contentOverflowY="auto"
        visible={watch("showDeleteModal")}
        onClose={onCloseModalDelete}
      >
        <ConfirmDeleteModal />
      </Modal>
      <Modal
        onBack={onCloseModalContratar}
        id="modal-contratar-postulation"
        contentOverflowY="auto"
        visible={watch("showContratarModal")}
      >
        <ConfirmContratarModal />
      </Modal>
      <Modal
        onBack={onCloseModalSelect}
        id="modal-seleccionar-postulation"
        contentOverflowY="auto"
        visible={watch("showSelectModal")}
      >
        <ConfirmSelectModal />
      </Modal>
    </>
  );
};

export default SolicitudModals;
