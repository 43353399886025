import React from "react";
import Container from "../../../../../ui/Container";
import { useFormContext, Controller } from "react-hook-form";
import { PostulationFormType } from "../../hookforms.interface";
import TextAreaField from "../../../../../ui/fields/TextAreaField";
import {
  changePostulation,
  ChangePostulationResponse,
} from "../../../../../shared/services/postulation.service";
import { useMutation } from "react-query";
import notification from "../../../../../ui/notification";
import Button from "../../../../../ui/Button";

const ConfirmSelectModal = () => {
  const { watch, setValue, control } = useFormContext<PostulationFormType>();

  const { isLoading: loadingChange, mutate: changeApprovedPostulationMutate } =
    useMutation<ChangePostulationResponse, Error>(
      async (): Promise<ChangePostulationResponse> => {
        return await changePostulation(watch("postulation.id"), {
          state: 1,
          description: watch("description"),
        });
      },
      {
        onSuccess: ({ data }) => {
          setValue(
            "postulations",
            watch("postulations").map((postulation) => {
              if (postulation.id === data.id) return data;
              return postulation;
            })
          );
          setValue("showSelectModal", false);
          setValue("description", "");
          notification({
            type: "success",
            message: data.name + " " + data.lastname + " aprobado",
          });
        },
        onError: (error: any) => {
          notification({
            type: "error",
            message: error.response.data.message,
          });
        },
      }
    );

  const onAccept = () => {
    if (watch("description").length === 0)
      return notification({
        type: "error",
        message: "El mensaje no puede ir vacio",
      });
    changeApprovedPostulationMutate();
  };

  const onCancel = () => {
    setValue("description", "");
    setValue("showSelectModal", false);
  };

  return (
    <Container
      padding="1.25rem"
      display="flex"
      flexDirection="column"
      gap=".625rem"
      width="100%"
      justifyContent="space-between"
    >
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextAreaField
            width="100%"
            label="Mensaje de correo"
            rows={10}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Container
        display="flex"
        justifyContent="flex-end"
        flexDirection="row"
        gap=".3125rem"
        width="100%"
      >
        <Button
          onClick={onAccept}
          loading={loadingChange}
          disabled={loadingChange}
          label={"Seleccionar"}
          display="default"
          trailingIcon="ri-check-line"
        />
        <Button
          onClick={onCancel}
          label={"Cancelar"}
          display="danger"
          trailingIcon="ri-close-line"
        />
      </Container>
    </Container>
  );
};

export default ConfirmSelectModal;
