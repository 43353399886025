import { AxiosResponse } from "axios";
import { CentralOfficeType } from "../types/central-office.type";
import axiosClient from "../utils/api/clientAxios";

const API = axiosClient.getUri();

const url = `${API}/central-office`;

/**
 * In this method we get all Central Offices from the API
 * @returns {Array<CentralOfficeType>}
 */
export const getAllCentralOffices = async (): Promise<
  AxiosResponse<CentralOfficeType[], CentralOfficeType[]>
> => {
  return await axiosClient.get(`${url}`);
};

/**
 * In this method we create a new Wrok Area from the API
 * @param {CentralOfficeType} centralOffice
 * @returns
 */
export const createCentralOffice = async (
  centralOffice: Omit<CentralOfficeType, "id">
): Promise<AxiosResponse<CentralOfficeType, CentralOfficeType>> => {
  return await axiosClient.post(`${url}`, centralOffice);
};

/**
 * In this method we edit a Central Office by Id from the API
 * @param {number} id Central Office's id
 * @param {CentralOfficeType} centralOffice
 * @returns
 */
export const editCentralOffice = async (
  id: number,
  centralOffice: Omit<CentralOfficeType, "id">
): Promise<AxiosResponse<CentralOfficeType, CentralOfficeType>> => {
  return await axiosClient.put(`${url}/${id}`, centralOffice);
};

/**
 * In this methods we delete a Central Office by Id from the API
 * @param {number} id Central Office's id
 * @returns
 */
export const deleteCentralOffice = async (
  id: number
): Promise<{ id: number }> => {
  return await axiosClient.delete(`${url}/${id}`);
};
