import React from "react";
import { useFormContext } from "react-hook-form";
import Table from "../../../../ui/Table";
import Column from "../../../../ui/Table/Column";
import { JobFormType } from "../hookforms.interfaces";
import JobRow from "./JobRow";

const JobsTable = () => {
  const { watch } = useFormContext<JobFormType>();

  const columns = (
    <tr>
      <Column align="left" width="5%" text="#"></Column>
      <Column align="left" width="30%" text="Nombre"></Column>
      <Column align="left" width="30%" text="Área de Trabajo"></Column>
      <Column align="left" width="20%" text="Sede"></Column>
      <Column text="Estado" width="5%"></Column>
      <Column text="Acciones" width="10%"></Column>
    </tr>
  );
  const rows = watch("jobs").map((job, index) => {
    return <JobRow key={index + job.name} index={index} job={job} />;
  });
  return <Table columns={columns} count={rows.length} rows={rows}></Table>;
};

export default JobsTable;
