import React from "react";
import { useFormContext } from "react-hook-form";
import { device } from "../../../../shared/breakpoints/reponsive";
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import Button from "../../../../ui/Button";
import Container from "../../../../ui/Container";
import { JobFormType } from "../hookforms.interfaces";

const JobsActions = () => {
  const greaterThanDesktopS = useMediaQuery(device.desktopS);
  const { setValue } = useFormContext<JobFormType>();

  const onModalJobOpen = () => {
    onCleanFields();
    setValue("showModalJob", true);
  };
  const onModalWorkAreaOpen = () => setValue("showModalWorkArea", true);
  const onModalCentralOfficeOpen = () =>
    setValue("showModalCentralOffice", true);

  const onCleanFields = () => {
    setValue("id", 0);
    setValue("name", "");
    setValue("description", "");
    setValue("centralOfficeId", 0);
    setValue("workAreaId", 0);
  };

  return (
    <Container
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="end"
      gap="1.5rem"
    >
      <Button
        shape={greaterThanDesktopS ? "default" : "round"}
        label={greaterThanDesktopS && "Áreas de Trabajo"}
        trailingIcon="ri-article-line"
        onClick={onModalWorkAreaOpen}
      ></Button>
      <Button
        shape={greaterThanDesktopS ? "default" : "round"}
        label={greaterThanDesktopS && "Sedes"}
        trailingIcon="ri-article-line"
        onClick={onModalCentralOfficeOpen}
      ></Button>
      <Button
        shape={greaterThanDesktopS ? "default" : "round"}
        label={greaterThanDesktopS && "Agregar Puesto de trabajo"}
        trailingIcon="ri-add-fill"
        onClick={onModalJobOpen}
      ></Button>
    </Container>
  );
};

export default JobsActions;
