import { AxiosResponse } from "axios";
import { WorkAreaType } from "../types/work-area.type";
import axiosClient from "../utils/api/clientAxios";

const API = axiosClient.getUri();

const url = `${API}/work-area`;

/**
 * In this method we get all Work Areas from the API
 * @returns {Array<WorkAreaType>}
 */
export const getAllWorkAreas = async (): Promise<
  AxiosResponse<WorkAreaType[], WorkAreaType[]>
> => {
  return await axiosClient.get(`${url}`);
};

/**
 * In this method we create a new Wrok Area from the API
 * @param {WorkAreaType} workArea
 * @returns
 */
export const createWorkArea = async (
  workArea: Omit<WorkAreaType, "id">
): Promise<AxiosResponse<WorkAreaType, WorkAreaType>> => {
  return await axiosClient.post(`${url}`, workArea);
};

/**
 * In this method we edit a Work Area by Id from the API
 * @param {number} id Work Area's id
 * @param {WorkAreaType} workArea
 * @returns
 */
export const editWorkArea = async (
  id: number,
  workArea: Omit<WorkAreaType, "id">
): Promise<AxiosResponse<WorkAreaType, WorkAreaType>> => {
  return await axiosClient.put(`${url}/${id}`, workArea);
};

/**
 * In this methods we delete a Work Area by Id from the API
 * @param {number} id Work Area's id
 * @returns
 */
export const deleteWorkArea = async (id: number): Promise<{ id: number }> => {
  return await axiosClient.delete(`${url}/${id}`);
};
