import { Outlet } from "react-router-dom";
import { useAcuapescaContext } from "../../contexts/AcuapescaProvider";
import RedirectRoute from "../RedirectRoute";

type AdminRoutesProps = {
  pathname: string;
};

const AdminRoutes: React.FC<AdminRoutesProps> = ({ pathname }) => {
  //TODO: Get isAuthenticated from context - useGeneralContext

  const {
    user: { user },
  } = useAcuapescaContext();

  if (user.role.name !== "Administrador")
    return <RedirectRoute pathname={pathname} />;
  return <Outlet />;
};

export default AdminRoutes;
