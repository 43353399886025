import React from "react";
import styled, { css } from "styled-components";
import Container from "../../../ui/Container";
import Img from "../../../ui/Img";
import logoAcuapesca from "../../../shared/assets/img/logo_acuapesca_2.png";
import backgorundAcua from "../../../shared/assets/img/fondoAcuapesca.jpg";
import { Link } from "react-router-dom";
import paths from "../../../shared/routes/paths";

const MenuGuest: React.FC = () => {
  return (
    <StyledHeader $img={backgorundAcua}>
      <StyledContainerImg>
        <Container width="150px">
          <Link to={paths.root}>
            <StyledImg placeholderImage="" src={logoAcuapesca} />
          </Link>
        </Container>
      </StyledContainerImg>
      <StyledNav></StyledNav>
    </StyledHeader>
  );
};

export default MenuGuest;

const StyledNav = styled.nav`
  width: 80%;
  height: 8rem;
  ${({ theme }) => css``}
`;

const StyledHeader = styled.header<{ $img: string }>`
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  height: 9.5rem;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 10px 20px;
  ${({ $img }) => css`
    background: url(${$img});
  `}
`;

const StyledContainerImg = styled(Container)`
  width: 450px;
  height: 8rem;
`;
const StyledImg = styled(Img)`
  width: 150px;
  ${({ theme }) => css``};
`;
