import { yupResolver } from "@hookform/resolvers/yup";
import { CentralOfficeType } from "../../../../../shared/types/central-office.type";
import yup from "../../../../../shared/yupLocale";

const CentralOfficeSchema: yup.SchemaOf<Omit<CentralOfficeType, "id">> = yup
  .object()
  .shape({
    name: yup.string().required("Esta campo es requerido"),
  });

export const ModalCentralOfficeResolver = yupResolver(CentralOfficeSchema);
