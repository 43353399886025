import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import {
  deletePostulation,
  DeletePostulationResponse,
} from "../../../../../shared/services/postulation.service";
import Button from "../../../../../ui/Button";
import Container from "../../../../../ui/Container";
import notification from "../../../../../ui/notification";
import { PostulationFormType } from "../../hookforms.interface";

const ConfirmDeleteModal = () => {
  const { watch, setValue } = useFormContext<PostulationFormType>();

  const onConfirm = () => {
    deletePostulationMutate();
  };

  const { mutate: deletePostulationMutate } = useMutation<
    DeletePostulationResponse,
    Error
  >(
    async (): Promise<DeletePostulationResponse> => {
      return await deletePostulation(watch("postulation.id"));
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "postulations",
          watch("postulations").filter(
            (postulation) => postulation.id !== data.id
          )
        );
        setValue("showViewModal", false);
        setValue("showDeleteModal", false);
        notification({
          type: "success",
          message: "Postulación Eliminada",
        });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );
  return (
    <Container
      padding="10px"
      width="100%"
      display="flex"
      flexDirection="column"
      gap="10px"
    >
      <Container
        width="100%"
        justifyContent="center"
        display="flex"
        alignItems="center"
      >
        ¿Estás seguro que deseas eliminar esta postulación?
      </Container>
      <Container display="flex" justifyContent="flex-end" gap="10px">
        <Button
          onClick={() => {
            setValue("showDeleteModal", false);
          }}
          label="Cancelar"
          display="default"
        />
        <Button
          onClick={onConfirm}
          display="danger"
          label="Eliminar"
          trailingIcon="ri-close-line"
        />
      </Container>
    </Container>
  );
};

export default ConfirmDeleteModal;
