import { yupResolver } from "@hookform/resolvers/yup";
import { JobType } from "../../../shared/types/job.type";
import yup from "../../../shared/yupLocale";

const JobSchema: yup.SchemaOf<Omit<JobType, "id" | "state">> = yup
  .object()
  .shape({
    name: yup.string().required("Este campo es requerido"),
    workAreaId: yup
      .number()
      .min(1, "Este campo es requerido")
      .required("Este campo es requerido"),
    centralOfficeId: yup
      .number()
      .min(1, "Este campo es requerido")
      .required("Este campo es requerido"),
    description: yup.string().required("Este campo es requerido"),
    img: yup.mixed().optional(),
  });

export const JobResolver = yupResolver(JobSchema);
