import React from "react";
import { useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import { useAadminContext } from "../../../../../../shared/contexts/AdminProvider";
import { CentralOfficeType } from "../../../../../../shared/types/central-office.type";
import Container from "../../../../../../ui/Container";
import CentralOfficeModalRow from "./CentralOfficeModalRow";

const CentralOfficeModalTable = () => {
  const {
    centralOffice: { centralOffices },
  } = useAadminContext();
  const { watch } = useFormContext<CentralOfficeType>();

  return (
    <StyledContainer width="100%" minHeight="100px" overFlowY="auto">
      <div>
        {centralOffices.map((workArea: CentralOfficeType, index: number) => {
          return (
            <CentralOfficeModalRow
              key={index}
              id={index + 1}
              workAreaId={workArea.id}
              selected={workArea.id === watch("id")}
              name={workArea.name}
            />
          );
        })}
      </div>
    </StyledContainer>
  );
};

export default CentralOfficeModalTable;

const StyledContainer = styled(Container)`
  ${({ theme }) => css`
    border-radius: 8px;
    border: 2px solid ${theme.colors.Neutral4};
  `}
`;
