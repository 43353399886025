import React from "react";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import styled, { css } from "styled-components";
import {
  changePostulation,
  ChangePostulationResponse,
} from "../../../../../shared/services/postulation.service";
import { PostulationType } from "../../../../../shared/types/postulation.type";
import Container from "../../../../../ui/Container";
import notification from "../../../../../ui/notification";
import Row from "../../../../../ui/Table/Row";
import { PostulationFormType } from "../../hookforms.interface";
import { JobType } from "../../../../../shared/types/job.type";
import Button from "../../../../../ui/Button";

type SolicitudRowProps = {
  index: number;
  postulation: PostulationType & { job: JobType };
};

const SolicitudRow: React.FC<SolicitudRowProps> = (props) => {
  const { watch, setValue } = useFormContext<PostulationFormType>();

  const {
    postulation: {
      id,
      name,
      email,
      lastname,
      gender,
      age,
      phone,
      view,
      state,
      job: { name: jobName },
    },
    postulation,
    index,
  } = props;

  const { mutate: changeViewPostulationMutate } = useMutation<
    ChangePostulationResponse,
    Error
  >(
    async (): Promise<ChangePostulationResponse> => {
      return await changePostulation(id, { view: true });
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "postulations",
          watch("postulations").map((postulation) => {
            if (postulation.id === data.id) return data;
            return postulation;
          })
        );
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const handleChangeViewPostulation = () => {
    changeViewPostulationMutate();
  };

  const onDelete = () => {
    setValue("showDeleteModal", true);
    setValue("postulation.id", id);
  };

  return (
    <StyledTr index={index}>
      <Row bold={!view}>{index + 1}</Row>
      <Row bold={!view}>{`${name} ${lastname}`}</Row>
      <Row bold={!view}>{email}</Row>
      <Row bold={!view} center>
        {age}
      </Row>
      <Row bold={!view} center>
        {gender}
      </Row>
      <Row bold={!view}>{phone}</Row>
      <Row bold={!view}>
        {state === 0
          ? "Postulante"
          : state === 1
          ? "Seleccionado"
          : "Contratado"}
      </Row>
      <Row bold={!view}>{jobName}</Row>
      <Row bold={!view} center>
        <Container
          width="100%"
          display="flex"
          flexDirection="row"
          justifyContent="space-evenly"
          alignItems="center"
          gap="10px"
          padding="5px 0"
        >
          <Button
            onClick={() => {
              setValue("showViewModal", true);
              setValue("postulation", postulation);
              if (!view) handleChangeViewPostulation();
            }}
            shape="round"
            display="default"
            trailingIcon="ri-eye-line"
          />
          <Button
            onClick={onDelete}
            shape="round"
            display="danger"
            trailingIcon="ri-close-line"
          />
        </Container>
      </Row>
    </StyledTr>
  );
};

export default SolicitudRow;

const StyledTr = styled.tr<{ index: number }>`
  ${({ theme, index }) => css`
    background-color: ${index % 2 === 0 ? `${theme.colors.Neutral3}` : `#fff`};
    :hover {
      background-color: ${theme.colors.Neutral4};
    }
  `}
`;
