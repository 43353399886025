import React from "react";
import { useFormContext } from "react-hook-form";
import { device } from "../../../../shared/breakpoints/reponsive";
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import Button from "../../../../ui/Button";
import Container from "../../../../ui/Container";
import { UserFormType } from "../hookforms.interfaces";

const UsersActions = () => {
  const greaterThanDesktopS = useMediaQuery(device.desktopS);
  const { setValue } = useFormContext<UserFormType>();
  const onModalUserOpen = () => {
    onCleanFields();
    setValue("showModalUser", true);
  };

  const onCleanFields = () => {
    setValue("id", 0);
    setValue("name", "");
    setValue("password", "");
    setValue("email", "");
    setValue("roleId", 0);
  };

  return (
    <Container
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="end"
    >
      <Button
        shape={greaterThanDesktopS ? "default" : "round"}
        label={greaterThanDesktopS && "Agregar Usuario"}
        trailingIcon="ri-add-fill"
        onClick={onModalUserOpen}
      />
    </Container>
  );
};

export default UsersActions;
