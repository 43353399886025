import React from "react";
import type CSS from "csstype";
import { css, IThemeColor } from "styled-components";
import styled from "styled-components";
import Container from "../Container";

type DivAttributes = React.HTMLAttributes<HTMLDivElement>;

interface SwitchProps extends DivAttributes {
  value?: boolean;
  width?: CSS.Property.Width;
  orientation?: "horizontal" | "vertical";
  id?: string;
  bgColorActivate?: keyof IThemeColor;
  bgColorDeactivate?: keyof IThemeColor;
  borderColor?: keyof IThemeColor;
  borderWidth?: CSS.Property.BorderWidth;
  borderStyle?: CSS.Property.BorderStyle;
  hoverBorderColor?: keyof IThemeColor;
  focusBorderColor?: keyof IThemeColor;
  activateIcon?: string;
  deActivateIcon?: string;
  activateText?: string;
  deActivateText?: string;
  onChange?: () => void;
}

const Switch: React.FC<SwitchProps> = (props) => {
  const {
    value = false,
    width = "100%",
    orientation = "vertical",
    bgColorActivate = "Success5",
    bgColorDeactivate = "Neutral4",
    borderColor = "Primary1",
    borderWidth = "1px",
    borderStyle = "solid",
    hoverBorderColor = "Primary1",
    focusBorderColor = "Primary1",
    activateIcon = "ri-check-line",
    deActivateIcon = "ri-close-line",
    activateText = "",
    deActivateText = "",
    onChange,
  } = props;
  const isRow = orientation === "horizontal";

  return (
    <StyledSwitchContainer width={width} isRow={isRow ? "row" : "column"}>
      <StyledContainer className="flex flex-col w-full rounded-md">
        <StyledContainerSphere
          value={value}
          bgColorActivate={bgColorActivate}
          bgColorDeactivate={bgColorDeactivate}
          borderColor={borderColor}
          borderWidth={borderWidth}
          borderStyle={borderStyle}
          hoverBorderColor={hoverBorderColor}
          focusBorderColor={focusBorderColor}
          onClick={() => {
            if (onChange) onChange();
          }}
        >
          <StyledContainerValue value={value}>
            {value && <i className={activateIcon} />}
            {!value && <i className={deActivateIcon} />}
          </StyledContainerValue>
          <Container
            width="100%"
            padding="0 0.25rem 0 0.50rem"
            display="flex"
            justifyContent={value ? "flex-start" : "flex-end"}
          >
            <span>{value ? activateText : deActivateText}</span>
          </Container>
        </StyledContainerSphere>
      </StyledContainer>
    </StyledSwitchContainer>
  );
};

export default Switch;

const StyledSwitchContainer = styled.div<{
  width: CSS.Property.Width;
  isRow: "row" | "column";
}>`
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  ${({ width, isRow }) => css`
    flex-direction: ${isRow};
    width: ${width};
  `};
`;



const StyledContainerValue = styled.div<{ value: boolean }>`
  ${({ value }) => css`
    background-color: #fff;
    border-radius: 50%;
    width: 1.75rem;
    height: 1.75rem;
    box-shadow: 0 1px 3px 0 #000, 0 1px 2px -1px #000;
    position: absolute;
    z-index: 10;
    margin: auto;
    transition: all 300ms;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: ${value ? "translateX(2rem);" : "translateX(0.25rem);"};
  `};
`;

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border-radius: 10px;
`;

// "rounded-2xl flex items-center relative transition-all duration-300",
// "w-16",
// "h-8",
// value ? bgColorActivate : bgColorDeactivate,
// borderColor,
// borderWidth,
// borderStyle,
// hoverBorderColor,
// focusBorderColor
const StyledContainerSphere = styled.div<{
  borderColor: keyof IThemeColor;
  borderWidth: CSS.Property.Width;
  value: boolean;
  borderStyle: CSS.Property.BorderStyle;
  hoverBorderColor: keyof IThemeColor;
  focusBorderColor: keyof IThemeColor;
  bgColorActivate: keyof IThemeColor;
  bgColorDeactivate: keyof IThemeColor;
}>`
  ${({
    borderColor,
    borderWidth,
    value,
    borderStyle,
    hoverBorderColor,
    focusBorderColor,
    bgColorActivate,
    bgColorDeactivate,
    theme,
  }) => css`
    width: 4rem;
    border-radius: 1rem;
    height: 2rem;
    display: flex;
    align-items: center;
    position: relative;
    transition: all 300ms;
    border-color: ${borderColor};
    border-width: ${borderWidth};
    background-color: ${value
      ? `${theme.colors[`${bgColorActivate}`]}`
      : `${theme.colors[`${bgColorDeactivate}`]}`};
    border-style: ${borderStyle};
    :hover {
      border-color: ${theme.colors[`${hoverBorderColor}`]};
    }
    :focus {
      border-color: ${theme.colors[`${focusBorderColor}`]};
    }
  `};
`;
