import { Outlet } from "react-router-dom";
import MenuGuest from "../../../components/Menus/MenuGuest";

type GuestRouteProps = {
  pathname: string;
};

const PublicRoutes: React.FC<GuestRouteProps> = () => {
  return (
    <>
      <MenuGuest />
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default PublicRoutes;
