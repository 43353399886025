import { AxiosResponse } from "axios";
import { RoleType } from "../types/role.type";
import axiosClient from "../utils/api/clientAxios";

const API = axiosClient.getUri();

const url = `${API}/role`;

/**
 * In this method we get all roles from the API
 * @param {LoginType} login
 * @returns {Promise<>}
 */
export const getAllRoles = async (): Promise<
  AxiosResponse<Array<RoleType>, Array<RoleType>>
> => {
  return await axiosClient.get(`${url}`);
};
