import React from "react";
import { useFormContext } from "react-hook-form";
import styled, { css } from "styled-components";
import { WorkAreaType } from "../../../../../../../shared/types/work-area.type";
import Container from "../../../../../../../ui/Container";
import Text from "../../../../../../../ui/Text";

type WorkAreaModalRowProps = {
  id: number;
  workAreaId: number;
  name: string;
  selected?: boolean;
  onClick?: (workAreaId: number) => void;
};

const WorkAreaModalRow: React.FC<WorkAreaModalRowProps> = (props) => {
  const { id, workAreaId, name, onClick, selected = false } = props;
  const { setValue } = useFormContext<WorkAreaType>();
  const onClickRow = () => {
    onClick?.(workAreaId);
    setValue("name", name);
    setValue("id", workAreaId);
  };

  return (
    <StyledContainer
      $selected={selected}
      width="100%"
      height="60px"
      display="flex"
      onClick={onClickRow}
    >
      <Container
        width="20%"
        height="100%"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        {id}
      </Container>
      <Container width="80%" height="100%" display="flex" alignItems="center">
        <Text.Body size="m" weight="regular" ellipsis>
          {name}
        </Text.Body>
      </Container>
    </StyledContainer>
  );
};

export default WorkAreaModalRow;

const StyledContainer = styled(Container)<{ $selected: boolean }>`
  ${({ theme, $selected }) => css`
    cursor: pointer;
    border-bottom: 2px solid ${theme.colors.Neutral4};
    background-color: ${$selected ? theme.colors.Neutral3 : "#fff"};
    &:hover {
      background-color: ${theme.colors.Neutral3};
    }
  `}
`;
