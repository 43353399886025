import { yupResolver } from "@hookform/resolvers/yup";
import { UserType } from "../../../shared/types/user.type";
import yup from "../../../shared/yupLocale";

const UserSchema: yup.SchemaOf<Omit<UserType, "id" | "state">> = yup
  .object()
  .shape({
    name: yup.string().required("Este campo es requerido"),
    roleId: yup
      .number()
      .min(1, "Este campo es requerido")
      .required("Este campo es requerido"),
    email: yup
      .string()
      .email("No tiene un formato válido")
      .required("Este campo es requerido"),
    password: yup.string().required("Este campo es requerido"),
  });

export const UserResolver = yupResolver(UserSchema);
