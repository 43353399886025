import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import { useAadminContext } from "../../../../../shared/contexts/AdminProvider";
import {
  createJob,
  CreateJobResponse,
  editJob,
  EditJobResponse,
} from "../../../../../shared/services/job.service";
import Button from "../../../../../ui/Button";
import Container from "../../../../../ui/Container";
import TextAreaField from "../../../../../ui/fields/TextAreaField";
import InputFile from "../../../../../ui/inputs/InputFile";
import notification from "../../../../../ui/notification";
import Select from "../../../../../ui/Select";
import { SelectItemType } from "../../../../../ui/Select/interfaces";
import { JobFormType } from "../../hookforms.interfaces";

const JobModal = () => {
  const {
    workArea: { workAreas },
    centralOffice: { centralOffices },
  } = useAadminContext();
  const {
    control,
    setValue,
    formState: { errors },
    handleSubmit,
    watch,
    getValues,
  } = useFormContext<JobFormType>();

  const optionsWorkArea: Array<SelectItemType> = workAreas.map((workArea) => {
    return {
      key: String(workArea.id),
      label: workArea.name,
    };
  });
  const optionsCentralOffice: Array<SelectItemType> = centralOffices.map(
    (centralOFfice) => {
      return {
        key: String(centralOFfice.id),
        label: centralOFfice.name,
      };
    }
  );
  const { isLoading: loadingCreateJob, mutate: createJobMutate } = useMutation<
    CreateJobResponse,
    Error
  >(
    async (): Promise<CreateJobResponse> => {
      const {
        id,
        jobs,
        showModalJob,
        showModalWorkArea,
        showModalCentralOffice,
        state,
        ...restJob
      } = getValues();
      return await createJob(restJob);
    },
    {
      onSuccess: ({ data }) => {
        setValue("jobs", [data, ...watch("jobs")]);
        setValue("showModalJob", false);
        onCleanFields();
        notification({ type: "success", message: "Puesto de Trabajo creado" });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );

  const { isLoading: loadingEditJob, mutate: editJobMutate } = useMutation<
    EditJobResponse,
    Error
  >(
    async (): Promise<EditJobResponse> => {
      const {
        id,
        jobs,
        showModalJob,
        showModalCentralOffice,
        showModalWorkArea,
        state,
        ...restJob
      } = getValues();
      return await editJob(id, restJob);
    },
    {
      onSuccess: ({ data }) => {
        setValue(
          "jobs",
          watch("jobs").map((job) => {
            if (job.id === data.id) return data;
            return job;
          })
        );
        setValue("showModalJob", false);
        onCleanFields();
        notification({ type: "success", message: "Puesto de Trabajo editado" });
      },
      onError: (error: any) => {
        notification({
          type: "error",
          message: error.response.data.message,
        });
      },
    }
  );
  const onCleanFields = () => {
    setValue("id", 0);
    setValue("name", "");
    setValue("description", "");
    setValue("workAreaId", 0);
    setValue("centralOfficeId", 0);
    setValue("img", "");
  };

  const onAddJob = () => {
    handleSubmit((e) => {
      createJobMutate();
    })();
  };

  const onEditJob = () => {
    handleSubmit(() => {
      editJobMutate();
    })();
  };

  return (
    <Container width="100%">
      <Container
        width="100%"
        height="100%"
        padding="20px"
        display="flex"
        flexDirection="column"
        gap="20px"
        justifyContent="space-between"
        alignItems={"center"}
      >
        {/* Info */}
        <Container
          width="100%"
          display="flex"
          flexDirection="column"
          gap="40px"
        >
          {/* name field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextAreaField
                  label="Nombre"
                  width="100%"
                  rows={1}
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
          </Container>

          {/* email field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="description"
              control={control}
              render={({ field }) => (
                <TextAreaField
                  width="100%"
                  label="Descripción"
                  rows={1}
                  value={field.value}
                  onChange={field.onChange}
                  hasError={!!errors.description}
                  helperText={errors.description?.message}
                />
              )}
            />
          </Container>

          {/* Work Area field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="workAreaId"
              control={control}
              render={({ field }) => (
                <Select
                  label="Área de Trabajo"
                  width="100%"
                  value={String(field.value)}
                  options={optionsWorkArea}
                  onChange={(key) => {
                    field.onChange(parseInt(key));
                  }}
                  hasError={!!errors.workAreaId}
                  helperText={errors.workAreaId?.message}
                />
              )}
            />
          </Container>

          {/* Central Office field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="centralOfficeId"
              control={control}
              render={({ field }) => (
                <Select
                  label="Sede"
                  width="100%"
                  value={String(field.value)}
                  options={optionsCentralOffice}
                  onChange={(key) => {
                    field.onChange(parseInt(key));
                  }}
                  hasError={!!errors.centralOfficeId}
                  helperText={errors.centralOfficeId?.message}
                />
              )}
            />
          </Container>

          {/* Img Field */}
          <Container width="100%" display="flex" gap="10px">
            <Controller
              name="img"
              control={control}
              render={({ field }) => (
                <InputFile
                  width="100%"
                  text="Sube una imagen"
                  value={typeof field.value === "string" ? field.value : ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files.length >= 1) {
                      field.onChange(e.target.files[0]);
                    }
                  }}
                />
              )}
            />
          </Container>
        </Container>

        {/* Button Submit */}
        <Container width="100%">
          <Button
            display="default"
            shape={"default"}
            width="100%"
            label={
              watch("id") === 0
                ? "Agregar Puesta de Trabajo"
                : "Editar Puesta de Trabajo"
            }
            trailingIcon={watch("id") === 0 ? "ri-add-fill" : "ri-edit-fill"}
            loading={loadingEditJob || loadingCreateJob}
            disabled={loadingEditJob || loadingCreateJob}
            onClick={watch("id") === 0 ? onAddJob : onEditJob}
          ></Button>
        </Container>
      </Container>
    </Container>
  );
};
export default JobModal;
