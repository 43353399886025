import React from "react";
import { FormProvider, useForm } from "react-hook-form";
import { CentralOfficeType } from "../../../../../shared/types/central-office.type";
import Container from "../../../../../ui/Container";
import { ModalCentralOfficeResolver } from "./CentralOffice.yup";
import CentralOfficeModalActions from "./CentralOfficeModalActions";
import CentralOfficeModalInfo from "./CentralOfficeModalInfo";
import CentralOfficeModalTable from "./CentralOfficeModalTable";

const CentralOfficeModal = () => {
  const formMethods = useForm<CentralOfficeType>({
    resolver: ModalCentralOfficeResolver,
    mode: "all",
    defaultValues: {
      id: 0,
      name: "",
    },
  });
  return (
    <FormProvider {...formMethods}>
      <Container width="100%">
        <Container
          width="100%"
          padding="20px"
          display="flex"
          flexDirection="column"
          gap="20px"
        >
          <CentralOfficeModalActions />

          <CentralOfficeModalInfo />

          <CentralOfficeModalTable />
        </Container>
      </Container>
    </FormProvider>
  );
};

export default CentralOfficeModal;
