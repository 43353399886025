import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { device } from "../../../../shared/breakpoints/reponsive";
import { useMediaQuery } from "../../../../shared/hooks/useMediaQuery";
import Container from "../../../../ui/Container";
import TextField from "../../../../ui/fields/TextField";
import Select from "../../../../ui/Select";
import { SelectItemType } from "../../../../ui/Select/interfaces";
import { PostulationFormType } from "../hookforms.interface";

const SolicitudesActions = () => {
  const { control, watch } = useFormContext<PostulationFormType>();
  const greaterThanDesktopS = useMediaQuery(device.desktopS);

  const optionsJob: Array<SelectItemType> = watch("jobs").map((job) => {
    return {
      key: String(job.id),
      label: job.name,
    };
  });
  const optionsState: Array<SelectItemType> = [
    {
      key: String(-1),
      label: "Todos",
    },
    {
      key: String(0),
      label: "Postulantes",
    },
    {
      key: String(1),
      label: "Seleccionado",
    },
    {
      key: String(2),
      label: "Contradado",
    },
  ];
  return (
    <Container
      width="100%"
      height="100%"
      display="flex"
      gap="10px"
      flexDirection={greaterThanDesktopS ? "row" : "column"}
      alignItems="center"
      justifyContent="start"
    >
      <Controller
        name="search.state"
        control={control}
        render={({ field }) => (
          <Select
            label="Estado"
            width={greaterThanDesktopS ? "33%" : "100%"}
            value={String(field.value)}
            options={optionsState}
            onChange={(key) => {
              field.onChange(parseInt(key));
            }}
          />
        )}
      />
      <Controller
        name="search.gender"
        control={control}
        render={({ field }) => (
          <Select
            label="Género"
            width={greaterThanDesktopS ? "33%" : "100%"}
            value={field.value}
            options={[
              { key: "Todos", label: "Todos" },
              { key: "M", label: "M" },
              { key: "F", label: "F" },
            ]}
            onChange={(key) => {
              field.onChange(key);
            }}
          />
        )}
      />
      <Controller
        name="search.jobId"
        control={control}
        render={({ field }) => (
          <Select
            label="Puestos de Trabajo"
            width={greaterThanDesktopS ? "33%" : "100%"}
            value={String(field.value)}
            options={[{ key: "0", label: "Todos" }, ...optionsJob]}
            onChange={(key) => {
              field.onChange(parseInt(key));
            }}
          />
        )}
      />

      <Controller
        name="search.age"
        control={control}
        render={({ field }) => (
          <TextField
            width={greaterThanDesktopS ? "33%" : "100%"}
            label="Edad Mayor o igual que"
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
    </Container>
  );
};

export default SolicitudesActions;
