/* eslint-disable react-hooks/exhaustive-deps */
import jwtDecode from "jwt-decode";
import {
  createContext,
  Dispatch,
  useContext,
  useEffect,
  useState,
} from "react";
import { usePersistedState } from "../hooks/usePersistedState";
import { RoleType } from "../types/role.type";
import { UserType } from "../types/user.type";
import storage from "../utils/storage";

const appAcuapescaUserStateKey = "acuapesca:user";

export type GlobalUser = UserType & { role: RoleType };
type AcuapescaContextType = {
  user: {
    user: GlobalUser;
    setUser: Dispatch<GlobalUser>;
  };
  auth: {
    isAuthenticated: boolean;
    setIsAuthenticated: Dispatch<boolean>;
  };
};

const initialCustomerState: GlobalUser = {
  id: 0,
  name: "",
  email: "",
  password: "",
  state: false,
  roleId: 0,
  role: {
    id: 0,
    name: "",
  },
};

export const AcuapescaContext = createContext({} as AcuapescaContextType);

export const useAcuapescaContext = () => {
  const context = useContext(AcuapescaContext);

  if (context === null) {
    throw new Error(
      "useAcuapescaContext must be used within a AcuapescaProvider"
    );
  }

  return context;
};

export const AcuapescaProvider = ({ children }: { children: JSX.Element }) => {
  const [usersState, setUsersState] = usePersistedState<GlobalUser>(
    appAcuapescaUserStateKey,
    initialCustomerState
  );

  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

  useEffect(() => {
    const token = storage.get<string>("token");
    if (!!token) {
      setIsAuthenticated(true);
      const userToken = jwtDecode<GlobalUser>(token);
      setUsersState(userToken);
    }
  }, []);

  return (
    <AcuapescaContext.Provider
      value={{
        user: {
          user: usersState,
          setUser: setUsersState,
        },
        auth: {
          isAuthenticated,
          setIsAuthenticated,
        },
      }}
    >
      {children}
    </AcuapescaContext.Provider>
  );
};
