import React, { ReactNode } from "react";
import Container from "../../../ui/Container";

type LayoutDashProps = {
  title: ReactNode;
  actions: ReactNode;
  table: ReactNode;
  modal: ReactNode;
};

const LayoutDash: React.FC<LayoutDashProps> = (props) => {
  const { actions, table, title, modal } = props;
  return (
    <Container
      width="100%"
      height="100%"
      padding="20px"
      display="flex"
      gap="1rem"
      flexDirection="column"
    >
      <Container width="100%" backgroundColor={"#fff"} height="2rem">
        {title}
      </Container>
      <Container
        width="100%"
        backgroundColor={"#fff"}
        display="flex"
        alignItems="center"
      >
        {actions}
      </Container>
      <Container
        width="100%"
        backgroundColor={"#fff"}
        height={"calc(100% - 5rem - 2rem - 2rem )"}
      >
        {table}
      </Container>
      {modal}
    </Container>
  );
};

export default LayoutDash;
