import React from "react";
import { useFormContext } from "react-hook-form";
import Table from "../../../../ui/Table";
import Column from "../../../../ui/Table/Column";
import { PostulationFormType } from "../hookforms.interface";
import SolicitudRow from "./SolicitudRow";

const SolicitudesTable = () => {
  const { watch } = useFormContext<PostulationFormType>();

  const columns = (
    <tr>
      <Column align="left" width="5%" text="#" />
      <Column align="left" width="25%" text="Nombre Completo" />
      <Column align="left" width="25%" text="Correo" />
      <Column align="center" width="1%" text="Edad" />
      <Column align="center" width="1%" text="Género" />
      <Column align="center" width="4%" text="Teléfono" />
      <Column align="center" width="4%" text="Estado" />
      <Column align="left" width="30%" text="Puesto de Trabajo" />
      <Column text="Acciones" width="5%" />
    </tr>
  );

  const rows = watch("postulations")
    .filter((item) => item.age >= watch("search.age"))
    .filter(
      (item) =>
        item.gender === watch("search.gender") ||
        watch("search.gender") === "Todos"
    )
    .filter(
      (item) =>
        item.jobId === watch("search.jobId") || watch("search.jobId") === 0
    )
    .filter(
      (item) =>
        item.state === watch("search.state") || watch("search.state") === -1
    )
    .map((postulation, index) => {
      return (
        <SolicitudRow
          key={index + postulation.name}
          index={index}
          postulation={postulation}
        />
      );
    });

  return <Table columns={columns} count={rows.length} rows={rows}></Table>;
};

export default SolicitudesTable;
