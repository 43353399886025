import { Outlet } from "react-router-dom";
import { useAcuapescaContext } from "../../contexts/AcuapescaProvider";
import RedirectRoute from "../RedirectRoute";

type GuestRouteProps = {
  pathname: string;
};

const GuestRoute: React.FC<GuestRouteProps> = ({ pathname }) => {
  //TODO: Get isAuthenticated from context - useGeneralContext
  // const isAuthenticated = false;
  const {
    auth: { isAuthenticated },
  } = useAcuapescaContext();

  if (isAuthenticated) return <RedirectRoute pathname={pathname} />;

  return (
    <>
      <main>
        <Outlet />
      </main>
    </>
  );
};

export default GuestRoute;
